import React, { useState } from 'react';
import axios from 'axios';

const ChatBot = () => {
  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState('');

  const handleQuestionChange = (event) => {
    setQuestion(event.target.value);
  };

  const submitQuestion = async () => {
    try {
      const messageData = [{ 'role': 'user', 'content': question }];
      const res = await axios.post('https://rxr9916.uta.cloud/openai_endpoint.php', { messages: messageData });

      if (res.data.message) {
        setAnswer(res.data.message);
      } else {
        setAnswer(res.data.choices[0].message.content);
      }
    } catch (error) {
      console.error("Error fetching response", error);
    }
  };

  return (
    <div style={{ backgroundColor: '#adbcad', minHeight:'60vh', minWidth:'80%',borderRadius:'8px', padding: '20px', maxWidth: '500px', margin: '0 auto' }}>
      <div style={{ display:'flex', flexDirection:'row', flexWrap:'wrap', marginBottom: '10px' }}>
        <input 
          type="text" 
          value={question} 
          onChange={handleQuestionChange} 
          style={{ borderRadius:'8px', width: '80%', padding: '10px', marginRight: '20px' }}
        />
        <button 
          onClick={submitQuestion} 
          style={{ borderRadius:'5px', padding: '10px', backgroundColor: 'green', color: 'white', cursor: 'pointer' }}
        >
          Send
        </button>
        
      </div>
      <div style={{ borderRadius:'10px',minHeight:'50vh', backgroundColor: '#f0f0f0', padding: '20px',marginTop: '15px' }}>
        AI Response: {answer}
      </div>
    </div>
  );
};

export default ChatBot;