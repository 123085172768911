import React, { useState, useEffect } from "react";
import InstructorNavbar from "./InstructorNavbar";
import axios from 'axios';
import CloseIcon from "@mui/icons-material/Close";
import { Modal, Box, Typography, TextField, Button, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { useUser } from "../../UserContext";
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer, CartesianGrid } from 'recharts';

interface Course {
  cid: number;
  course_name: string;
  passPercentage: number;
}
interface Exam {
  exam_id: number;
  mean: number;
  low: number;
  high: number;
  total_marks: String;
  date: string;
  students: number;
  exam_style: string;       // Add this line
  course_id: number;        // Add this line
  exam_title: string;       // Add this line
  exam_type: string;        // Add this line
  duration: string;         // Add this line
  format: string;           // Add this line
  num_questions: String;    // Add this line
  weightage: String;        // Add this line
  time:String;
}
export default function InstructorAssignmentsList() {
  const [open, setOpen] = useState(false);
  const { userId, setUserId } = useUser();
  const [courses, setCourses] = useState<Course[]>([]);
  const [instructorName, setInstructorName] = useState('');
  const [exams, setExams] = useState<Exam[]>([]);
  const [exams2, setExams2] = useState<Exam[]>([]);
  const [selectedCourseId, setSelectedCourseId] = useState<number | null>(null);
  const [selectedExamData, setSelectedExamData] = useState<any[]>([]);

  const [examCourseId, setExamCourseId] = useState<number | ''>('');
  const [examId, setExamId] = useState("");
  const examStyle = "Assignment";


  const [isModalOpen, setIsModalOpen] = useState(false);
  const [examTitle, setExamTitle] = useState("");
  
  
  const [totalMarks, setTotalMarks] = useState("");
  const [format, setFormat] = useState("");
  const [numQuestions, setNumQuestions] = useState("");
  const [weightage, setWeightage] = useState("");
  const [openExamModal, setOpenExamModal] = useState(false);
  const handleOpenExamModal = () => {
    setOpenExamModal(true);
  };

  const handleCloseExamModal = () => {
    setOpenExamModal(false);
    // Clear the exam fields
    setExamTitle("");
    setTotalMarks("");
    setFormat("");
    setNumQuestions("");
    setWeightage("");
  };

  // Function to handle the submit action
  const handleAddExam = () => {
    // You would submit these values to your backend here
    console.log({
      examTitle,
      totalMarks,
      format,
      numQuestions,
      weightage
    });

    handleCloseExamModal(); // Close the modal and clear the fields
  };
  useEffect(() => {
    if (userId) {
      axios.post('https://rxr9916.uta.cloud/profile.php', { user_id: userId })
        .then(response => {
          // Check if there is an error in the response
          if (response.data.error) {
            console.error("Error:", response.data.error);
          } else {
            // Assuming the response data has the user's first and last name
            const { fname, lname } = response.data;
            setInstructorName(`${fname} ${lname}`);
          }
        })
        .catch(error => {
          console.error("Error fetching instructor information:", error);
        });
    }
  }, [userId]); // This effect runs when the userId changes.
  //const [courses, setCourses] = useState([]);

  useEffect(() => {
    // Assuming you fetch the instructor's ID and set it in the userId state
    if (userId) {
      axios.post('https://rxr9916.uta.cloud/coursesinstructor.php', { instructorName: instructorName })
        .then(response => {
          if (Array.isArray(response.data)) {
            setCourses(response.data);
          } else if (response.data.error) {
            console.error("Error:", response.data.error);
          } else {
            console.error("Unexpected response:", response.data);
          }
        })
        .catch(error => {
          console.error("Error fetching courses:", error);
        });
    }
  }, [userId, instructorName]);

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "#ffffff",
    borderRadius: 2,
    boxShadow: 24,
    p: 4,
  };
  const handleExamClick = (exam: Exam) => {
    // Prepare the data for the bar chart
    const chartData = [
      { name: 'Mean', value: exam.mean },
      { name: 'Low', value: exam.low },
      { name: 'High', value: exam.high }
    ];
    setSelectedExamData(chartData);
  };
  const handleCourseClick = (cid: number) => {
    console.log(`Selected Course ID: ${cid}`);
    setSelectedCourseId(cid);

    // Make sure headers are set to application/json
    axios.post('https://rxr9916.uta.cloud/pcexams.php', JSON.stringify({ course_id: cid }), {
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(response => {
        console.log('Response:', response); // Print the whole response for debugging
        console.log('Response data:', response.data); // Print the response data for debugging

        // Check if the response data is an array
        if (Array.isArray(response.data)) {
          setExams(response.data);
        } else {
          console.error('Data is not an array', response.data);
          // If the response is not an array, handle it as needed
          // For example, check for an error field in the response
          if (response.data.error) {
            console.error('Error fetching exams:', response.data.error);
          }
        }
      })
      .catch(error => {
        console.error('Error fetching exams:', error);
      });
      axios.post('https://rxr9916.uta.cloud/getexamsinfocourse.php', JSON.stringify({ course_id: cid }), {
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(response => {
        console.log('Response exams info:', response); // Print the whole response for debugging
        console.log('Response data exam sinfo:', response.data); // Print the response data for debugging

        // Check if the response data is an array
        if (Array.isArray(response.data)) {
          setExams2(response.data);
        } else {
          console.error('Data is not an array', response.data);
          // If the response is not an array, handle it as needed
          // For example, check for an error field in the response
          if (response.data.error) {
            console.error('Error fetching exams info:', response.data.error);
          }
        }
      })
      .catch(error => {
        console.error('Error fetching exams info:', error);
      });
      
  };
  const handleModalOpen = () => {
    setOpen(true);
  };
  // Function to open the modal
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  // Function to close the modal and clear form fields
  const handleCloseModal = () => {
    setIsModalOpen(false);
    // Clear the form fields
    setExamTitle('');
    
    setTotalMarks('');
    setFormat('');
    setNumQuestions('');
    setWeightage('');
    
  };

  // Function to handle form submission
  // Function to handle form submission
const handleSubmit = (event) => {
  event.preventDefault(); // Prevent default form submission behavior

  // Prepare the data to be sent to the PHP backend
  const formData = {
    exam_id: examId,
    exam_style: examStyle,
    course_id: examCourseId,
    exam_title: examTitle,
    
    total_marks: totalMarks,
    format: format,
    num_questions: numQuestions,
    weightage: weightage,
  };

  // Perform the POST request to your PHP endpoint
  axios.post('https://rxr9916.uta.cloud/addexaminfo.php', formData)
    .then(response => {
      // Handle the response from the server here
      console.log('Response from the server:', response.data);

      // You might want to check if the insert was successful and then do something
      if (response.data.success) {
        // Maybe clear the form or show a success message
      }
    })
    .catch(error => {
      // Handle any errors here
      console.error('Error submitting form:', error);
    });

  handleCloseExamModal(); // Close the modal after form submission
};

  const handleClose = () => {
    setOpen(false);
  };
  const [editExamModalOpen, setEditExamModalOpen] = useState(false);
  const [editingExam, setEditingExam] = useState<Exam | null>(null);
  
  // Function to open edit modal and set current exam
  const editCourseExamInfo = (exam: Exam) => {
    setEditingExam(exam);
    setEditExamModalOpen(true);
  };
  
  // Function to handle form submission for exam update
  const handleUpdateExam = (event) => {
    event.preventDefault();
    if (editingExam) {
      // Replace with your update API endpoint
      axios.post('https://rxr9916.uta.cloud/updateexaminfo.php', editingExam)
        .then(response => {
          console.log('Updated exam response:', response.data);
          window.location.reload();

        })
        .catch(error => {
          console.error('Error updating exam:', error);
        });
    }
    setEditExamModalOpen(false);
  };
  const deleteExam = (examId) => {
    // Confirm deletion
    if(window.confirm("Are you sure you want to delete this exam?")) {
      axios.post('https://rxr9916.uta.cloud/deletecourseinfo.php', { exam_id: examId })
        .then(response => {
          console.log('Delete exam response:', response.data);
          // Handle success (e.g., refresh the exam list, show a message)
          window.location.reload();
        })
        .catch(error => {
          console.error('Error deleting exam:', error);
        });
    }
  };
  
  return (
    <div className="page-container">
      {/* MAIN CONTENT = NO HEADER AND FOOTER */}
      <div className="main-container">
        {/* LEFT NAVBAR */}
        <InstructorNavbar wrapperClass="left-navbar"
          menuItemClass="nav-button"/>

        {/* COURSE BOXES */}
       
        <div>
        
        <button
  type="submit"
  className="mark button primary-button"
  style={{
    backgroundColor: 'green', // Add green background color
    // color: 'white', // Set text color to white
    display: 'block', // Make it a block element
    margin: '0 auto', // Center horizontally
    marginTop: '20px', // Add some top margin
  }}
  onClick={handleModalOpen}
>
  Add Assignment
</button>
      
          <>
          <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-title" variant="h6" component="h2">
            Add New Assignment
          </Typography>
          <Box component="form" noValidate autoComplete="off" onSubmit={handleSubmit}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="exam_id"
              label="Assignment ID"
              name="exam_id"
              value={examId}
              onChange={(e) => setExamId(e.target.value)}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="exam_title"
              label="Assignment Title"
              name="exam_title"
              value={examTitle}
              onChange={(e) => setExamTitle(e.target.value)}
            />
           
            <FormControl fullWidth margin="normal">
              <InputLabel id="course-select-label">Course</InputLabel>
              <Select
                labelId="course-select-label"
                id="course-select"
                value={selectedCourseId}
                label="Course"
                onChange={(e) => setExamCourseId(Number(e.target.value))}
              >
                {courses.map((course) => (
                  <MenuItem key={course.cid} value={course.cid}>{course.course_name}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              margin="normal"
              required
              fullWidth
              id="total_marks"
              label="Total Marks"
              name="total_marks"
              value={totalMarks}
              onChange={(e) => setTotalMarks(e.target.value)}
            />
            {/* <TextField
              margin="normal"
              required
              fullWidth
              id="format"
              label="Format"
              name="format"
              value={format}
              onChange={(e) => setFormat(e.target.value)}
            /> */}
            <TextField
              margin="normal"
              required
              fullWidth
              id="num_questions"
              label="Number of Questions"
              name="num_questions"
              value={numQuestions}
              onChange={(e) => setNumQuestions(e.target.value)}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="weightage"
              label="Weightage"
              name="weightage"
              value={weightage}
              onChange={(e) => setWeightage(e.target.value)}
            />
            <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
              Add Assignment
            </Button>
            <Button fullWidth variant="outlined" sx={{ mt: 1, mb: 2 }} onClick={handleCloseModal}>
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>
            {/* <Modal
             
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
               
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    marginTop: 10,
                  }}
                >
                  <form onSubmit={handleSubmit}>
                    <fieldset>
                      <legend>Add New Exam</legend>
                      <label>
          Assignment ID:
          <input
            type="text"
            value={examId}
            onChange={(e) => setExamId(e.target.value)}
            required
          />
        </label>
        <br />
        <p>
         Type: <strong>{examStyle}</strong>
        </p>
                      <label>
                        Course:
                        <select value={examCourseId} onChange={(e) => setExamCourseId(Number(e.target.value))} required>
                          <option value="">Select a course</option>
                          {courses.map((course) => (
                            <option key={course.cid} value={course.cid}>{course.course_name}</option>
                          ))}
                        </select>
                      </label>
                      <br />

                      <label>
                        Title:
                        <input type="text" value={examTitle} onChange={(e) => setExamTitle(e.target.value)} required />
                      </label>
                      <br />
                     
                      <br />
                     
                     
                      <label>
                        Total Marks:
                        <input type="text" value={totalMarks} onChange={(e) => setTotalMarks(e.target.value)} required
                          pattern="\d+"
                          title="Total marks should be a number." />
                      </label>
                      <br />
                      <label>
                        Format:
                        <select value={format} onChange={(e) => setFormat(e.target.value)} required>
                          <option value="mcq">Multiple Choice</option>
                          <option value="short">Short Answer</option>
                          <option value="essay">Essay</option>
                          <option value="file">File Upload</option>
                          <option value="file">Mixed</option>
                        </select>
                      </label>
                      <br />
                      <label>
                        Number of Questions/Parts:
                        <input type="text" value={numQuestions} onChange={(e) => setNumQuestions(e.target.value)} required pattern="\d+"
                          title="Number of questions should be a number." />
                      </label>
                      <br />
                      <label>
                        Weightage:
                        <input type="text" value={weightage} onChange={(e) => setWeightage(e.target.value)} required pattern="\d+"
                          title="Weightage should be a number." />
                      </label>
                      <br></br>

                      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <input type="submit" value="Submit" />
                      </div>
                    </fieldset>
                  </form>

                </div>
              </Box>
            </Modal> */}
          </>
          {isModalOpen && (
            <div className="modal">
              <div className="modal-content">
                <button type="submit" onClick={() => setIsModalOpen(false)}>Close</button>

              </div>
            </div>
          )}
          {/* <p>User ID: {userId}</p>
          <p>User Name: {instructorName}</p> */}

          <div className="content">
            {courses.map((course, index) => (
              <div className="course-box" key={course.cid} onClick={() => handleCourseClick(course.cid)}>
                <a href="#">
                  {/* Course {index + 1}<br /><br /><br /> */}
                  {course.course_name}
                </a>
              </div>
            ))}
{selectedCourseId && exams2.length > 0 && (
   <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '20px' }}>
   <thead style={{ backgroundColor: '#4CAF50', color: 'white' }}>
      <tr>
       
        <th style={{ border: '1px solid #ddd', padding: '8px'}}>Exam ID</th>
        <th style={{ border: '1px solid #ddd', padding: '8px'}}>Exam Title</th>
        
        <th style={{ border: '1px solid #ddd', padding: '8px'}}>Total Marks</th>
       
        <th style={{ border: '1px solid #ddd', padding: '8px'}}> Number of Questions</th>
        <th style={{ border: '1px solid #ddd', padding: '8px'}}>Weightage</th>
        <th style={{ border: '1px solid #ddd', padding: '8px'}}> Time</th>
        <th style={{ border: '1px solid #ddd', padding: '8px'}}>Actions</th> 
      </tr>
    </thead>
    <tbody>
      {exams2.map((exam, index) => (
        <tr key={index} style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : 'white' }}>
          
          <td>
          <a href="#" onClick={() => editCourseExamInfo(exam)}>{exam.exam_id}</a>
            </td>
          <td style={{ border: '1px solid #ddd', padding: '8px'}}>{exam.exam_title}</td>
         
          <td style={{ border: '1px solid #ddd', padding: '8px'}}>{exam.total_marks}</td>
          
          <td style={{ border: '1px solid #ddd', padding: '8px'}}>{exam.num_questions}</td>
          <td style={{ border: '1px solid #ddd', padding: '8px'}}>{exam.weightage}</td>
          <td style={{ border: '1px solid #ddd', padding: '8px'}}>{exam.time}</td>
          <td style={{ border: '1px solid #ddd', padding: '8px'}}>
            <button  type="submit" style={{ backgroundColor: 'red', color: 'white' }}onClick={() => deleteExam(exam.exam_id)}>Delete</button> {/* Delete button */}
          </td>
        </tr>
      ))}
    </tbody>
  </table>
)}
{editExamModalOpen && (
  <Modal open={editExamModalOpen} onClose={() => setEditExamModalOpen(false)}>
    <Box sx={style}>
      <Typography variant="h6">Edit Exam</Typography>
      <Box component="form" noValidate autoComplete="off" onSubmit={handleUpdateExam}>
        <TextField
          margin="normal"
          required
          fullWidth
          label="Exam Title"
          value={editingExam?.exam_title || ''}
          onChange={(e) => setEditingExam({ ...editingExam, exam_title: e.target.value })}
        />
         <TextField
                margin="normal"
                fullWidth
                id="exam_id"
                label="Exam ID"
                value={editingExam?.exam_id||''}
                disabled // Exam ID should not be editable
              />
           
           
           <TextField
              margin="normal"
              required
              fullWidth
              id="total_marks"
              label="Total Marks"
              name="total_marks"
              value={editingExam?.total_marks||''}
              onChange={(e) =>setEditingExam({...editingExam, total_marks:e.target.value } )}
            />
            {/* <TextField
              margin="normal"
              required
              fullWidth
              id="format"
              label="Format"
              name="format"
              value={format}
              onChange={(e) => setFormat(e.target.value)}
            /> */}
            <TextField
              margin="normal"
              required
              fullWidth
              id="num_questions"
              label="Number of Questions"
              name="num_questions"
              value={editingExam?.num_questions||''}
              onChange={(e) =>setEditingExam({...editingExam, num_questions:e.target.value})}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="weightage"
              label="Weightage"
              name="weightage"
              value={editingExam?.weightage||''}
              onChange={(e) =>setEditingExam({...editingExam, weightage:e.target.value})}
            />
        <Button type="submit" variant="contained">Update Exam</Button>
      </Box>
    </Box>
  </Modal>
)}

            {selectedCourseId && exams.length > 0 && (
              <>
                {/* <h3>Exams for {courses.find(c => c.id === selectedCourseId)?.name}:</h3> */}
                <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '20px' }}>
              <thead style={{ backgroundColor: '#4CAF50', color: 'white' }}>
                    <tr>
                      <th style={{ border: '1px solid #ddd', padding: '8px' }}>Exam ID</th>
                      <th style={{ border: '1px solid #ddd', padding: '8px' }}>Students</th>
                      <th style={{ border: '1px solid #ddd', padding: '8px' }}>Mean</th>
                      <th style={{ border: '1px solid #ddd', padding: '8px' }}>Low</th>
                      <th style={{ border: '1px solid #ddd', padding: '8px' }}>High</th>
                      <th style={{ border: '1px solid #ddd', padding: '8px' }}>Total Marks</th>
                      <th style={{ border: '1px solid #ddd', padding: '8px' }}>Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {exams.map((exam, index) => (
                      <tr key={index}  style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : 'white' }}>
                        <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                          <a href="#!" onClick={(e) => { e.preventDefault(); handleExamClick(exam); }}>
                            {exam.exam_id}
                          </a>
                        </td>
                        <td style={{ border: '1px solid #ddd', padding: '8px' }}>{exam.students}</td>
                        <td style={{ border: '1px solid #ddd', padding: '8px' }}>{exam.mean.toFixed(2)}</td>
                        <td style={{ border: '1px solid #ddd', padding: '8px' }}>{exam.low}</td>
                        <td style={{ border: '1px solid #ddd', padding: '8px' }}>{exam.high}</td>
                        <td style={{ border: '1px solid #ddd', padding: '8px' }}>{exam.total_marks}</td>
                        <td style={{ border: '1px solid #ddd', padding: '8px' }}>{exam.date}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>

                {selectedExamData.length > 0 && (
                  <ResponsiveContainer width="100%" height={300}>
                    <BarChart data={selectedExamData} key={Date.now()}>
                      <CartesianGrid strokeDasharray="0.1 0.1" />
                      <XAxis dataKey="name" />
                      <YAxis />
                      <Tooltip
                        cursor={{ fill: 'transparent' }}
                        contentStyle={{ backgroundColor: 'rgba(255,255,255,0.85)', border: 'none' }}
                        formatter={(value, name) => [value, name]}
                      />
                      <Legend />
                      <Bar
                        dataKey="value"
                        fill="#2a84d8"
                        barSize={30}
                        isAnimationActive={true}
                        animationDuration={1200}
                        // Add animations for the bar
                        onAnimationStart={() => console.log('Animation started')}
                        onAnimationEnd={() => console.log('Animation ended')}
                      />
                    </BarChart>
                  </ResponsiveContainer>
                )}

              </>
            )}
          </div>

        </div>
      </div>
     
    </div>
  );
}