import React, { useState, useEffect } from "react";
import InstructorNavbar from "./InstructorNavbar";
import axios from 'axios';
import { Modal, Box, Typography, TextField, Button, Select, MenuItem, FormControl, InputLabel } from '@mui/material';

import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import { useUser } from "../../UserContext";
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer, CartesianGrid } from 'recharts';

interface Course {
  cid: number;
  tm:String;
  course_name: string;
  passPercentage: number;
}

interface StudentMarks {
  [studentId: string]: number | null;
}


interface Exam {
  exam_id: number;
  mean: number;
  low: number;
  high: number;
  total_marks: String;
  date: string;
  students: number;
  exam_style: string;       // Add this line
  course_id: number;        // Add this line
  exam_title: string;       // Add this line
  exam_type: string;        // Add this line
  duration: string;         // Add this line
  format: string;           // Add this line
  num_questions: String;    // Add this line
  weightage: String;        // Add this line
  time:String;
}
export default function InstructorExamsList() {
  const [open, setOpen] = useState(false);
  const { userId, setUserId } = useUser();
  const [courses, setCourses] = useState<Course[]>([]);
  const [instructorName, setInstructorName] = useState('');
  const [exams, setExams] = useState<Exam[]>([]);
  const [exams2, setExams2] = useState<Exam[]>([]);
  const [selectedCourseId, setSelectedCourseId] = useState<number | null>(null);
  const [selectedExamData, setSelectedExamData] = useState<any[]>([]);
  const [students, setStudents] = useState([]);
  const [examCourseId, setExamCourseId] = useState<number | ''>('');
  // const [marks, setMarks] = useState(''); // State to hold marks
  // const [examId, setExamId] = useState("");
  // const examStyle = "exam";
  const [selectedExamId, setSelectedExamId] = useState<number | null>(null);
  const [examId, setExamId] = useState('');
  const [studentId, setStudentId] = useState('');
  const [courseId, setCourseId] = useState('');
  const [score, setScore] = useState('');
  const [totalMarks, setTotalMarks] = useState('');
  const [date, setDate] = useState('');
  // const [studentMarks, setStudentMarks] = useState('');
  const [studentMarks, setStudentMarks] = useState<StudentMarks>({});

  // const [isModalOpen, setIsModalOpen] = useState(false);
  // const [examTitle, setExamTitle] = useState("");
  // const [examType, setExamType] = useState("");
  // const [duration, setDuration] = useState("");
  // const [totalMarks, setTotalMarks] = useState("");
  // const [format, setFormat] = useState("");
  // const [numQuestions, setNumQuestions] = useState("");
  // const [weightage, setWeightage] = useState("");
  // const [openExamModal, setOpenExamModal] = useState(false);
  // const [editExamModalOpen, setEditExamModalOpen] = useState(false);
  // const [editingExam, setEditingExam] = useState<Exam | null>(null);
  // const handleMarksChange = (event) => {
  //   setMarks(event.target.value);
  // };
  
  const handleScoreChange = (event) => {
    setScore(event.target.value);
    setStudentMarks(event.target.value);
    console.log(setStudentMarks);
  };
  useEffect(() => {
    console.log("this is what ", studentMarks); // Log to see what's in the state
  }, [studentMarks]);
  const handleTotalMarksChange = (event) => {
    setTotalMarks(event.target.value);
  };
  
  const handleSubmit = async (studentId, examId, totalMarks, courseId, e) => {
    e.preventDefault(); // To prevent page refresh
  
    // Create the data object
    const data = {
      exam_id: examId,
      student_id: studentId,
      course_id: courseId,
      score: score,
      total_marks: totalMarks,
      date: new Date().toISOString().slice(0, 10) // Format the date as YYYY-MM-DD
    };
  
    try {
      // Use JSON.stringify() to convert the data object to a JSON string
      const response = await axios.post('https://rxr9916.uta.cloud/insert_marks.php', JSON.stringify(data), {
        headers: {
          'Content-Type': 'application/json' // Set the content type header for JSON
        }
      });
  
      console.log(response.data);
      // Handle response or set state as needed
    } catch (error) {
      console.error('Error submitting data:', error);
    }
  };
  
  
  useEffect(() => {
    if (userId) {
      axios.post('https://rxr9916.uta.cloud/profile.php', { user_id: userId })
        .then(response => {
          // Check if there is an error in the response
          if (response.data.error) {
            console.error("Error:", response.data.error);
          } else {
            // Assuming the response data has the user's first and last name
            const { fname, lname } = response.data;
            setInstructorName(`${fname} ${lname}`);
          }
        })
        .catch(error => {
          console.error("Error fetching instructor information:", error);
        });
    }
  }, [userId]); // This effect runs when the userId changes.
  //const [courses, setCourses] = useState([]);

  useEffect(() => {
    // Assuming you fetch the instructor's ID and set it in the userId state
    if (userId) {
      axios.post('https://rxr9916.uta.cloud/coursesinstructor.php', { instructorName: instructorName })
        .then(response => {
          if (Array.isArray(response.data)) {
            setCourses(response.data);
          } else if (response.data.error) {
            console.error("Error:", response.data.error);
          } else {
            console.error("Unexpected response:", response.data);
          }
        })
        .catch(error => {
          console.error("Error fetching courses:", error);
        });
    }
  }, [userId, instructorName]);

  
  const fetchStudentMarks = async (studentId, courseId, examId) => {
    try {
      const response = await axios.post('https://rxr9916.uta.cloud/getstudmarks.php', JSON.stringify({
        student_id: studentId,
        course_id: courseId,
        exam_id: examId
      }), {
        headers: {
          'Content-Type': 'application/json'
        }
      });
  
      // Check the structure of response.data and extract marks correctly
      return response.data.score; // assuming the API returns { score: value }
    } catch (error) {
      console.error('Error fetching student marks:', error);
      return null; // Return null or a default value in case of an error
    }
  };
  
  
  const handleCourseClick = (cid: number) => {
    console.log(`Selected Course ID: ${cid}`);
    setSelectedCourseId(cid);
    // fetchStudentsForExam(cid);
    // Make sure headers are set to application/json
    axios.post('https://rxr9916.uta.cloud/pcexams.php', JSON.stringify({ course_id: cid }), {
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(response => {
        console.log('Response:', response); // Print the whole response for debugging
        console.log('Response data:', response.data); // Print the response data for debugging

        // Check if the response data is an array
        if (Array.isArray(response.data)) {
          setExams(response.data);
        } else {
          console.error('Data is not an array', response.data);
          // If the response is not an array, handle it as needed
          // For example, check for an error field in the response
          if (response.data.error) {
            console.error('Error fetching exams:', response.data.error);
          }
        }
      })
      .catch(error => {
        console.error('Error fetching exams:', error);
      });
      
      axios.post('https://rxr9916.uta.cloud/getexamsinfocourse2.php', JSON.stringify({ course_id: cid }), {
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(response => {
          console.log('Response exams info:', response); // Print the whole response for debugging
          console.log('Response data exam sinfo:', response.data); // Print the response data for debugging
  
          // Check if the response data is an array
          if (Array.isArray(response.data)) {
            setExams2(response.data);
          } else {
            console.error('Data is not an array', response.data);
            // If the response is not an array, handle it as needed
            // For example, check for an error field in the response
            if (response.data.error) {
              console.error('Error fetching exams info:', response.data.error);
            }
          }
        })
        .catch(error => {
          console.error('Error fetching exams info:', error);
        });
  };
  const fetchStudentsForExam = (cid: number,tm:String) => {
    // Replace with the actual API endpoint and request body
    axios
      .post(
        "https://rxr9916.uta.cloud/fetchstudents.php",
        JSON.stringify({ course_id: cid ,totalmarksexam:tm}),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        console.log("Students response:", response); // Print the response for debugging
        if (Array.isArray(response.data)) {
          // fetchGradesForCourse(selectedCourseId);
          // Now make another request to get detailed information of these students
         
          axios
            .post("https://rxr9916.uta.cloud/getstudnetslist.php", {

              student_ids: response.data.map((s) => s.student_id),
            })
            .then((detailedResponse) => {
              // Update the state with detailed information
              setStudents(detailedResponse.data);
            })
            .catch((error) => {
              console.error(
                "Error fetching detailed student information:",
                error
              );
            });
        } else {
          console.error("Data is not an array", response.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching student IDs:", error);
      });
  };
  useEffect(() => {
    const fetchAllMarks = async () => {
      const marksPromises = students.map(student => 
        fetchStudentMarks(student.id, selectedCourseId, selectedExamId)
      );
    
      try {
        const marks = await Promise.all(marksPromises);
        const newStudentMarks = students.reduce((acc, student, index) => {
          acc[student.id] = marks[index];
          return acc;
        }, {});
        setStudentMarks(newStudentMarks);
        console.log(newStudentMarks); // Add this to log the updated state
      } catch (error) {
        console.error('Error fetching marks for all students:', error);
      }
    };
  
    if (students.length > 0 && selectedCourseId && selectedExamId) {
      fetchAllMarks();
    }
  }, [students, selectedCourseId, selectedExamId]);
  

  return (
    <div className="page-container">
      {/* MAIN CONTENT = NO HEADER AND FOOTER */}
      <div className="main-container">
        {/* LEFT NAVBAR */}
        <InstructorNavbar wrapperClass="left-navbar"
          menuItemClass="nav-button"/>

        {/* COURSE BOXES */}
        <div>
        
          <div className="content">
            {courses.map((course, index) => (
              <div className="course-box" key={course.cid} onClick={() => handleCourseClick(course.cid)}>
                <a href="#">
                  {/* Course {index + 1}<br /><br /><br /> */}
                  {course.course_name}
                </a>
              </div>
            ))}
{selectedCourseId && exams2.length > 0 && (
  <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '20px' }}>
              <thead style={{ backgroundColor: '#4CAF50', color: 'white' }}>
      <tr>
       
        <th style={{ border: '1px solid #ddd', padding: '8px'}}>Exam ID</th>
        <th style={{ border: '1px solid #ddd', padding: '8px'}}>Exam Title</th>
        
        <th style={{ border: '1px solid #ddd', padding: '8px'}}>Total Marks</th>
       
        <th style={{ border: '1px solid #ddd', padding: '8px'}}>Number of Questions</th>
        <th style={{ border: '1px solid #ddd', padding: '8px'}}>Weightage</th>
        <th style={{ border: '1px solid #ddd', padding: '8px'}}>Time</th>
        {/* <th style={{ border: '1px solid #ddd', padding: '8px'}}>Actions</th>  */}
      </tr>
    </thead>
    <tbody>
      {exams2.map((exam, index) => (
        <tr key={index} style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : 'white' }}>
          
          <td>
          <a href="#" onClick={() => { 
        setSelectedExamId(exam.exam_id); // Set the selected exam ID
        setTotalMarks(exam.total_marks.toString()); // Set the total marks for the selected exam
        fetchStudentsForExam(selectedCourseId, exam.total_marks);
      }}>{exam.exam_id}</a>
            </td>
          <td style={{ border: '1px solid #ddd', padding: '8px'}}>{exam.exam_title}</td>
         
          <td  style={{ border: '1px solid #ddd', padding: '8px'}}>{exam.total_marks}</td>
          
          <td style={{ border: '1px solid #ddd', padding: '8px'}}>{exam.num_questions}</td>
          <td style={{ border: '1px solid #ddd', padding: '8px'}}>{exam.weightage}</td>
          <td style={{ border: '1px solid #ddd', padding: '8px'}}>{exam.time}</td>
          
        </tr>
      ))}
    </tbody>
  </table>
)}
{selectedCourseId && students.length > 0 && (
              <table
                style={{
                  width: "80%",
                  borderCollapse: "collapse",
                  marginTop: "20px",
                }}
              >
                <thead style={{ backgroundColor: "#4CAF50", color: "white" }}>
                  <tr>
                    <th
                      style={{
                        border: "1px solid #ddd",
                        borderTopLeftRadius: "10px",
                        padding: "8px",
                      }}
                    >
                      Student ID
                    </th>
                    <th style={{ border: "1px solid #ddd", padding: "8px" }}>
                      First Name
                    </th>
                    <th style={{ border: "1px solid #ddd", padding: "8px" }}>
                      Last Name
                    </th>
                    <th style={{ border: "1px solid #ddd", padding: "8px" }}>
                      Email
                    </th>
                    <th style={{ border: "1px solid #ddd", padding: "8px" }}>
                      Score
                    </th>
                    {/* <th style={{ border: '1px solid #ddd', padding: '8px' }}>Grade</th> */}
                    <th style={{ border: "1px solid #ddd", padding: "8px" }}>
                      Enter Marks
                    </th>
                    <th style={{ border: "1px solid #ddd", padding: "8px" }}>
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {students.map((student, index) => (
                    <tr
                      key={index}
                      style={{
                        backgroundColor: index % 2 === 0 ? "#f2f2f2" : "white",
                      }}
                    >
                      <td
                        style={{
                          border: "1px solid #ddd",
                          padding: "8px",
                          textAlign: "center",
                        }}
                      >
                        {student.id}
                      </td>
                      <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                        {student.fname}
                      </td>
                      <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                        {student.lname}
                      </td>
                      <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                        {student.email}
                      </td>
                      <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                      {studentMarks[student.id] !== undefined ? studentMarks[student.id] : 'Loading...'}
                      </td>
                      {/* <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'center' }}>{student.grade || 'Not Set'}</td> */}
                      <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                      <input type="number" value={score} onChange={handleScoreChange}/>
                      </td>
                      <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                      <button type ="submit" onClick={(e) => handleSubmit(student.id, selectedExamId,totalMarks, selectedCourseId, e)}>Save</button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}

          </div>

        </div>
      </div>
     
    </div>
  );
}