import React, { useState, useEffect } from 'react';
import '../../css/global-styles.css';
import StudentNavbar from './StudentNavbar';
import axios from 'axios';
import { useUser } from '../../UserContext';
import ChatMessages from '../ChatMessages';
import ChatContent from '../ChatContent';
// import ChatContent from './ChatContent';

function StudentDiscussion() {
  const { userId, setUserId } = useUser();
  const [selectedRole, setSelectedRole] = useState('');
  const [selectedUser, setSelectedUser] = useState('');
  const [usersWithRole, setUsersWithRole] = useState([]);
  const [formData, setFormData] = useState({
    receiver: '',
    message: '',
  });
  const [chatRecord, setChatRecord] = useState([]);
  const [selectedReceiverId, setSelectedReceiverId] = useState('');

  useEffect(() => {
    fetchDataForRole();
  }, [selectedRole]);

  const handleRoleChange = (e) => {
    setSelectedRole(e.target.value);
  };

  const fetchDataForRole = () => {
    axios
      .post('https://rxr9916.uta.cloud/chatAdd.php', { role: selectedRole })
      .then((response) => {
        setUsersWithRole(response.data);
        setSelectedUser(response.data[0].id)
      })
      .catch((error) => {
        console.error('Error fetching user data:', error);
      });
  };

  const handleSelectedUser = (e) => {
    setSelectedUser(e.target.value);
    setSelectedReceiverId(e.target.value);

    axios
      .post('https://rxr9916.uta.cloud/fetch_chat_history.php', {
        sender_id: userId,
        receiver_id: e.target.value,
      })
      .then((response) => {
        setChatRecord(response.data.chat_messages);
      })
      .catch((error) => {
        console.error('Error fetching chat history:', error);
      });
  };

  const handleFormChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    axios
      .post('https://rxr9916.uta.cloud/new_chat.php', {
        role: selectedRole,
        receiver_id: selectedUser,
        message: formData.message,
        sender_id: userId,
      })
      .then((response) => {
        let prevChat = response.data.chat_messages;
        const currentTime = new Date();
        setChatRecord((prevChat) => [
          ...prevChat,
          { message: formData.message, sender_id: userId, receiver_id: selectedUser, time: currentTime },
        ]);
        setFormData({ ...formData, message: '' });
      })
      .catch((error) => {
        console.error('Error sending message:', error);
      });
  };

  useEffect(() => {
    if (selectedUser && userId) {
      fetchChatMessages();
      const intervalId = setInterval(fetchChatMessages, 500); // Fetch messages every 3 seconds
      return () => clearInterval(intervalId); 
    }
  }, [selectedUser, userId]);

  const fetchChatMessages = () => {
    axios
    .post('https://rxr9916.uta.cloud/fetch_chat_history.php', {
      sender_id: userId,
      receiver_id: selectedUser,
    })
    .then((response) => {
      setChatRecord(response.data.chat_messages);
    })
    .catch((error) => {
      console.error('Error fetching chat history:', error);
    });
  };

  return (
    <div className="page-container">
      <div className="c1main-container">
        <StudentNavbar wrapperClass="left-navbar" menuItemClass="c1nav-button" />
        
        <ChatContent
          selectedRole={selectedRole}
          usersWithRole={usersWithRole}
          selectedUser={selectedUser}
          formData={formData}
          handleRoleChange={handleRoleChange}
          handleSelectedUser={handleSelectedUser}
          handleFormChange={handleFormChange}
          handleSubmit={handleSubmit}
          chatRecord={chatRecord}
          userId={userId}
          setChatRecord={setChatRecord}
        />
      </div>
    </div>
  );
}

export default StudentDiscussion;