import React, { useEffect, useState } from "react";
import axios from 'axios';
import "../global-styles.less";
import { Link } from "react-router-dom";
import homepageimg1 from "../assets/homepageimg1.jpg";
import homepageimg2 from "../assets/homepageimg2.jpg";
import homepageimg3 from "../assets/homepageimg3.jpg";
import Footer from "./Footer";
import Header from "./Header";
import { routes } from "../constants";

const links = [
  routes.about,
  {
    to: "https://sxr3538.uta.cloud/blogging/",
    labelText: "Blog",
  },
  routes.login,
  routes.signup,
];

function HomePage() {
  const [goals, setGoals] = useState([]);
  useEffect(() => {
    const fetchGoals = async () => {
      try {
        const response = await axios.get('https://rxr9916.uta.cloud/fetchpcgoalsdefault.php');
        setGoals(response.data); // Assuming the response is the array of goals
      } catch (error) {
        console.error('Failed to fetch goals:', error);
      }
    };

    fetchGoals();
  }, []);
 

  return (
    <div className="global-styles-ui">
      <div className="homepage_container">
        <div className="head">
          <Header links={links} />
          <hr />
        </div>

        <div className="home-container">
          <div className="home-image-container">
            <img src={homepageimg1} alt="Image 1" />
          </div>
          <div className="home-text-container">
            <h2>Flexibility and Accessibility</h2>
            <p>
              AcadeXellence give students the freedom to access course
              materials, homework, and resources from any location with an
              internet connection and at any time. By allowing students to work
              at their own pace and around their schedules, this accessibility
              makes education more accessible and flexible. AcadeXellence offer
              a wide range of educational resources, including slideshows,
              documents, and tests. This variety accommodates various learning
              preferences and can improve the educational process.
            </p>
          </div>
        </div>

        <div className="home-container reversed">
          <div className="home-image-container">
            <img src={homepageimg2} alt="Image 2" />
          </div>
          <div className="home-text-container">
            <h2>Assessment and Reports</h2>
            <p>
              AcadeXellence provide assessment tools that enable instructors to
              develop quizzes, assignments, and exams. With automated grading
              and instant feedback, students can easily understand their
              progress and where they can improve. Learning analytics monitor
              student engagement, engagement, and performance. Comprehensive
              reports help educators identify students at risk and refine their
              teaching methods to improve learning outcomes.
            </p>
          </div>
        </div>

        <div className="home-container triple-images">
          <div className="home-image-container">
            <img src={homepageimg3} alt="Image 3" />
          </div>
          <div className="home-text-container">
            <h2>Improvement and Security</h2>
            <p>
              Platforms such as AcadeXellence are constantly updating and
              improving their features based on feedback from users and
              technological progress. This flexibility means that the platform
              is always up-to-date and meets the changing needs of learners and
              educators. Features such as secure login are important for
              establishing trust with users. AcadeXellence offers comprehensive
              support services and resources to help users navigate and take
              advantage of the platform's features.
            </p>
          </div>
          <p></p>
        </div>
        <div className="home-text-container">
        <h2>Program Goals</h2>
        {goals.length > 0 ? (
            <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '20px' }}>
              <thead style={{ backgroundColor: '#4CAF50', color: 'white' }}>
                <tr>
                  <th style={{ border: '1px solid #ddd', padding: '8px' }}>Index</th>
                  <th style={{ border: '1px solid #ddd', padding: '8px' }}>Goal</th>
                </tr>
              </thead>
              <tbody>
                {goals.map((goal, index) => (
                  <tr key={goal.index} style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : 'white' }}>
                    <td style={{ border: '1px solid #ddd', padding: '8px' }}>{index + 1}</td>
                    <td style={{ border: '1px solid #ddd', padding: '8px' }}>{goal.pcgoals}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p>Loading goals...</p>
          )}
      </div>

      </div>
    </div>
  );
}

export default HomePage;
