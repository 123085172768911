import React, { useState, useEffect } from "react";
import PCNavbar from "./PCNavbar";
import FeedbackAnalysis from './FeedbackAnalysis';
interface Feedback {
  feedbackid: number;
  role: string;
  name: string;
  email: string;
  subject: string;
  feedback: string;
}
interface Feedbackstudent {
  id: number;
role: string;
  name: string;
  email: string;
  subject: string;
  feedback: string;
}
function PCQueries() {
  const [feedbackList, setFeedbackList] = useState<Feedback[]>([]);
  const [feedbackList2, setFeedbackList2] = useState<Feedbackstudent[]>([]);
  useEffect(() => {
    fetch('https://rxr9916.uta.cloud/fetchfeedback.php')
      .then((response) => response.json())
      .then((data) => setFeedbackList(data))
      .catch((error) => console.error('Fetch error:', error));
  }, []);
  useEffect(() => {
    fetch('https://rxr9916.uta.cloud/fetchfeedbackstudent.php')
      .then((response) => response.json())
      .then((data) => setFeedbackList2(data))
      .catch((error) => console.error('Fetch error:', error));
  }, []);

  const handleDelete = (feedbackId: number) => {
    // Send a delete request to your PHP server
    // Replace 'http://yourdomain.com/deletefeedback.php' with the actual path to your PHP script
    fetch(`https://rxr9916.uta.cloud/deletefeedback.php?feedbackid=${feedbackId}`, {
      method: 'POST', // or 'DELETE' if your server supports it
    })
    .then((response) => {
      if (response.ok) {
        setFeedbackList(feedbackList.filter((feedback) => feedback.feedbackid !== feedbackId));
      } else {
        throw new Error('Delete action was not successful.');
      }
    })
    .catch((error) => console.error('Delete error:', error));
  };
  const handleDelete2 = (studentId: number) => {
    // Send a delete request to your PHP server
    // Replace 'http://yourdomain.com/deletestudentfeedback.php' with the actual path to your PHP script
    fetch(`https://rxr9916.uta.cloud/deletefeedback2.php?id=${studentId}`, {
      method: 'POST', // or 'DELETE' if your server supports it
    })
      .then((response) => {
        if (response.ok) {
          setFeedbackList2(feedbackList2.filter((feedback) => feedback.id !== studentId));
        } else {
          throw new Error('Delete action was not successful.');
        }
      })
      .catch((error) => console.error('Delete error:', error));
  };


  return (
    <div className="pcrpage_container">
      <div className="main-container">
        <PCNavbar />
        <div className="right-content">
          <div className="PC_container-card-grid">
            <div className="card-row-enquiries">
              <p>
                Feedback forms submitted by Students are shown here. Feedback
                form can include course feedback and queries
              </p>
              <br />
              {feedbackList.map((feedback) => (
                <div className="card-enquiries" key={feedback.feedbackid}>
                  <div className="student-info-enquiries">
                    <p>Student Name: {feedback.name}</p>
                    <p>Student ID: {feedback.feedbackid}</p>
                    <p>
                      Student Mail:{" "}
                      <a href={`mailto:${feedback.email}`}>
                        {feedback.email}
                      </a>
                    </p>
                    <p>Subject: {feedback.subject}</p>
                    <p>Feedback: {feedback.feedback}</p>
                    <button type="submit" onClick={() => handleDelete(feedback.feedbackid)}>
                      Delete
                    </button>
                  </div>
                </div>
              ))}
                {Array.isArray(feedbackList2) && feedbackList2.length > 0 && (
                <h2 style={{ color: 'blue', textAlign: 'center' }}>
                  Student Feedback Forms
                </h2>
              )}
               {Array.isArray(feedbackList2) && feedbackList2.length === 0 && (
                <p style={{ textAlign: 'center', fontStyle: 'italic' }}>
                  No student feedback forms to display
                </p>
              )}
                {Array.isArray(feedbackList2) &&  feedbackList2.map((feedback) => (
                <div className="card-enquiries" key={feedback.id}>
                  <div className="student-info-enquiries">
                    <p>Student Name: {feedback.name}</p>
                    <p>Student ID: {feedback.id}</p>
                    <p>
                      Student Mail:{" "}
                      <a href={`mailto:${feedback.email}`}>
                        {feedback.email}
                      </a>
                    </p>
                    <p>Subject: {feedback.subject}</p>
                    <p>Feedback: {feedback.feedback}</p>
                    <button type="submit" onClick={() => handleDelete2(feedback.id)}>
                      Delete
                    </button>
                  </div>
                </div>
              ))}
               <FeedbackAnalysis />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PCQueries;
