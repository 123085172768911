
import React from 'react';

function ChatMessages({ chatRecord, userId, senderName, receiverName }) {
  const getMessageStyle = (isSent) => ({
    backgroundColor: isSent ? '#486391': 'white'  ,
    color: isSent ? 'white' : 'black',
    alignSelf: isSent ? 'flex-end':'flex-start'  ,
    
  });
  const chatStyles = `
    .chat-container {
      border: 1px solid #ccc;
      border-radius: 5px;
      background-color: #9da99d;
      max-width: 400px;
      margin: 0 auto;
      padding: 10px;
      box-shadow: 0px 0px 5px #ccc;
    }

    .old-chats-header {
      background-color: white;
      color: black;
      text-align: center;
      padding: 5px;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }
    .chat-messages::-webkit-scrollbar {
      width: 8px;
    }
    .chat-message:hover {
      background-color: #e0e0e0;
    }

    .chat-message {
      transition: background-color 0.3s;
    }

    
    .chat-messages::-webkit-scrollbar-thumb {
      background-color: #aaa;
      border-radius: 4px;
    }
    
    .chat-messages::-webkit-scrollbar-track {
      background-color: #f5f5f5;
    }
    
    .chat-messages {
      display: flex;
    padding: 10px;
    overflow-y: auto;
    max-height: 300px;
    flex-direction: column;
    flex-wrap: nowrap;
    }

    .chat-message {
      padding: 10px;
      margin-bottom: 10px;
      border-radius: 5px;
      width: 70%;
    }

    .chat-message-header{
      display:flex;
      justify-content:space-between;
      align-items:baseline;
      flex-wrap: wrap;
    }

    .sent {
      background-color: #f5f5f5;
      color: black;
      align-self: flex-start; /* Updated: align sent messages to the left */
    }

    .received {
      background-color: #f5f5f5;
      color: #333;
      align-self: flex-end; /* Corrected: align received messages to the right */
    }
    



    .message-content {
      word-wrap: break-word;
      text-align:left;
    }

    .message-time {
      font-size: 10px;
      
    }
  `;

  return (
    <div>
      <style>{chatStyles}</style>
      <div className="chat-container">
        <div className="old-chats-header">
          <b>
            <h3>Messages</h3>
          </b>
          
        </div>
        <div className="chat-messages">
          {chatRecord.map((message, index) => (
            <div
            key={index}
            className="chat-message"
            style={getMessageStyle(message.sender_id === userId)}
            >
              <div className="chat-message-header">
                {message.sender_id === userId ? (
                  <div className="message-sender">You</div>
                ) : (
                  <div className="message-sender">{senderName}</div>
                )}
                <div className="message-time">
                  {new Date(message.time).toLocaleString()}
                </div>
              </div>
              <div className="message-content">{message.message}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
export default ChatMessages;