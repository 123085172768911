// import React, {useState, useEffect } from 'react';
// import axios from 'axios';
// import { useNavigate } from 'react-router-dom';
import Header from './Header';
import { routes } from "../constants";
// const ForgotPassword = () => {
//   const [email, setEmail] = useState('');
//   const [code, setCode] = useState('');
//   const [newPassword, setNewPassword] = useState('');
//   const [otpSent, setOtpSent] = useState(false);
//   const [otpVerified, setOtpVerified] = useState(false);
//   const [timer, setTimer] = useState(5);
//   const [message, setMessage] = useState('');
//   const navigate = useNavigate();
//   const handleSendOtp = async () => {
//     setMessage("Sending OTP...");
//     try {
//       const response = await axios.post('https://rxr9916.uta.cloud/check_email.php', `email=${email}`);
//       if (response.data === 'success') {
//         const otpResponse = await axios.post('https://rxr9916.uta.cloud/send_otp.php', `email=${email}`);
//         if (otpResponse.data === 'success') {
//           setOtpSent(true);
//           setMessage('OTP sent to email');
//           startTimer();
//         } else {
//           setMessage(otpResponse.data);
//         }
//       } else {
//         setMessage(response.data);
//       }
//     } catch (error) {
//       setMessage('Error sending OTP');
//     }
//     setOtpSent(true);
//     setTimer(5);
//   };

//   const handleResendOtp = async () => {
//     try {
//       const response = await axios.post('https://rxr9916.uta.cloud/check_email.php', `email=${email}`);
//       if (response.data === 'success') {
//         const otpResponse = await axios.post('https://rxr9916.uta.cloud/send_otp.php', `email=${email}`);
//         if (otpResponse.data === 'success') {
//           setOtpSent(true);
//           setMessage('OTP sent to email');
//           startTimer();
//         } else {
//           setMessage(otpResponse.data);
//         }
//       } else {
//         setMessage(response.data);
//       }
//     } catch (error) {
//       setMessage('Error sending OTP');
//     }
//     setMessage("Resending OTP...");
//     setTimer(5); 
//   };

//   const handleVerifyOtp = async () => {
//     setMessage("Verifying OTP...");
//     try {
//       const response = await axios.post('https://rxr9916.uta.cloud/verify_otp.php', `email=${email}&code=${code}`);
//       if (response.data === 'success') {
//         setOtpVerified(true);
//         setMessage('OTP verified');
//       } else {
//         setMessage(response.data);
//       }
//     } catch (error) {
//       setMessage('Error verifying OTP');
//     }
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     //setMessage("Resetting password...");
//     try {
//       const response = await axios.post('https://rxr9916.uta.cloud/reset_password.php', `email=${email}&newPassword=${newPassword}`);
//       const responseData = response.data;

//       if (responseData === 'Password reset successfully') {
//         setMessage(responseData);

//         // Fetch role and redirect
//         const roleResponse = await axios.post('https://rxr9916.uta.cloud/get_role.php', `email=${email}`);
//         const role = roleResponse.data.trim();

//         const roleRedirectUrls = {
//           'admin' :'https://rxr9916.uta.cloud/admin',
//                     'student' : 'https://rxr9916.uta.cloud/student',
//                     'instructor' : 'https://rxr9916.uta.cloud/instructor',
//                     'qao' : 'https://rxr9916.uta.cloud/qa',
//                     'pc' : 'https://rxr9916.uta.cloud/pc'
//         };
//         const redirectPath = roleRedirectUrls[role];
//         const redirectUrl = roleRedirectUrls[role];
//         if (redirectPath) {
//           // window.location.href = redirectUrl;
//         } else {
//           alert('Unknown role: ' + role);
//       }      
//       } else {
//         setMessage(responseData);
//       }
//     } catch (error) {
//       setMessage('Error resetting password');
//     }
//   };

//   const startTimer = () => {
//     setTimer(5);
//     const interval = setInterval(() => {
//       setTimer((prevTimer) => prevTimer - 1);
//     }, 1000);
//     setTimeout(() => {
//       clearInterval(interval);
//       setTimer(5);
//     }, 5000);
//   };
//   useEffect(() => {
//     let interval;
//     if (timer > 0) {
//       interval = setInterval(() => {
//         setTimer((prevTimer) => prevTimer - 1);
//       }, 1000);
//     }
//     return () => clearInterval(interval);
//   }, [timer]);
// return(
//   <div className="global-styles-ui">
//       <div className="pcrpage_container">
//   <div className="head">
//   <Header links={[routes.login, routes.signup, routes.home]} />
//           <hr />
//         </div>
//   <div className="login-container">
//   <form onSubmit={handleSubmit}>
//   <fieldset id="signinfo" className="filldetails for_phone">
//     <br />
//   <div id="signinfohorizontal">
//     <label>Email:</label>
//     <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
//     </div>
//     <br />
//     <button type="submit" onClick={otpSent ? handleResendOtp : handleSendOtp} disabled={otpSent && timer > 0}>
//       {otpSent ? 'Resend Code' : 'Send Code'}
//     </button>
//     {otpSent && timer > 0 && <span> ({timer}s)</span>}
//     <br />

//     {otpSent && (
//       <>
//       <div id="signinfohorizontal">
//         <label>Code:</label>
//         <input type="text" value={code} onChange={(e) => setCode(e.target.value)} required />
//         </div>
//         <br />
//         <button type="submit" onClick={handleVerifyOtp}>Verify Code</button>
//         <br />
//       </>
//     )}

//     {otpVerified && (
//       <>
//         <div id="signinfohorizontal">
//         <label>New Password:</label>
//         <br />
//         <input type="password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} required />
//         </div>
//         <br />
//         <button type="submit">Reset Password</button>
//         <br />
//       </>
//     )}

//     {message && <p>{message}</p>}
//     </fieldset>
//   </form>
//   </div>
// </div>
// </div>
// );
// };

// export default ForgotPassword;
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [otpSent, setOtpSent] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);
  const [timer, setTimer] = useState(5);
  const [message, setMessage] = useState('');
  const navigate = useNavigate();
  const handleSendOtp = async () => {
    setMessage("Sending OTP...");
    try {
      const response = await axios.post('https://rxr9916.uta.cloud/check_email.php', `email=${email}`);
      if (response.data === 'success') {
        const otpResponse = await axios.post('https://rxr9916.uta.cloud/send_otp.php', `email=${email}`);
        if (otpResponse.data === 'success') {
          setOtpSent(true);
          setMessage('OTP sent to email');
          startTimer();
        } else {
          setMessage(otpResponse.data);
        }
      } else {
        setMessage(response.data);
      }
    } catch (error) {
      setMessage('Error sending OTP');
    }
    setOtpSent(true);
    setTimer(5);
  };

  const handleResendOtp = async () => {
    try {
      const response = await axios.post('https://rxr9916.uta.cloud/check_email.php', `email=${email}`);
      if (response.data === 'success') {
        const otpResponse = await axios.post('https://rxr9916.uta.cloud/send_otp.php', `email=${email}`);
        if (otpResponse.data === 'success') {
          setOtpSent(true);
          setMessage('OTP sent to email');
          startTimer();
        } else {
          setMessage(otpResponse.data);
        }
      } else {
        setMessage(response.data);
      }
    } catch (error) {
      setMessage('Error sending OTP');
    }
    // setMessage("Resending OTP...");
    setTimer(5); 
  };

  const handleVerifyOtp = async () => {
    setMessage("Verifying OTP...");
    try {
      const response = await axios.post('https://rxr9916.uta.cloud/verify_otp.php', `email=${email}&code=${code}`);
      if (response.data === 'success') {
        setOtpVerified(true);
        setMessage('OTP verified');
      } else {
        setMessage(response.data);
      }
    } catch (error) {
      setMessage('Error verifying OTP');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    //setMessage("Resetting password...");
    try {
      const response = await axios.post('https://rxr9916.uta.cloud/reset_password.php', `email=${email}&newPassword=${newPassword}`);
      const responseData = response.data;

      if (responseData === 'Password reset successfully') {
        setMessage(responseData);

        // Fetch role and redirect
        const roleResponse = await axios.post('https://rxr9916.uta.cloud/get_role.php', `email=${email}`);
        const role = roleResponse.data.trim();

        const roleRedirectUrls = {
          'admin' :'https://rxr9916.uta.cloud/admin',
                    'student' : 'https://rxr9916.uta.cloud/student',
                    'instructor' : 'https://rxr9916.uta.cloud/instructor',
                    'qao' : 'https://rxr9916.uta.cloud/qa',
                    'pc' : 'https://rxr9916.uta.cloud/pc'
        };
        const redirectPath = roleRedirectUrls[role];
        const redirectUrl = roleRedirectUrls[role];
        if (redirectPath) {
          window.location.href = redirectUrl;
        } else {
          console.error('Unknown role:', role);
        }
      } else {
        setMessage(responseData);
      }
    } catch (error) {
      setMessage('Error resetting password');
    }
  };

  const startTimer = () => {
    setTimer(5);
    const interval = setInterval(() => {
      setTimer((prevTimer) => prevTimer - 1);
    }, 1000);
    setTimeout(() => {
      clearInterval(interval);
      setTimer(5);
    }, 5000);
  };
  useEffect(() => {
    let interval;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [timer]);

  const centeredFormStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh'
  };

  const formContainerStyle = {
    backgroundColor: '#fefefe',
    padding: '20px',
    border: '1px solid #888',
    width: '300px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'
  };

  const formGroupStyle = {
    marginBottom: '15px'
  };

  const labelStyle = {
    display: 'block',
    marginBottom: '5px'
  };

  const inputStyle = {
    width: '100%',
    padding: '10px',
    marginBottom: '10px',
    border: '1px solid #ddd',
    boxSizing: 'border-box'
  };

  const buttonStyle = {
    width: '100%',
    padding: '10px',
    backgroundColor: 'green',
    color: 'white',
    border: 'none',
    cursor: 'pointer'
  };

  
return(
  <div style={centeredFormStyle}>
  <form onSubmit={handleSubmit}  style={formContainerStyle}>
  <div style={formGroupStyle}>
    <label>Email:</label>
    <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
    <br />
    </div>
    <button type="button" onClick={otpSent ? handleResendOtp : handleSendOtp} disabled={otpSent && timer > 0} style={buttonStyle}>
      {otpSent ? 'Resend Code' : 'Send Code'}
    </button>
    {otpSent && timer > 0 && <span> ({timer}s)</span>}
    <br />

    {otpSent && (
      <>
        <label>Code:</label>
        <input type="text" value={code} onChange={(e) => setCode(e.target.value)} required />
        <br />
        <button type="button" onClick={handleVerifyOtp}>Verify Code</button>
        <br />
      </>
    )}

    {otpVerified && (
      <>
        <label>New Password:</label>
        <input type="password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} required />
        <br />
        <button type="submit">Reset Password</button>
      </>
    )}

    {message && <p>{message}</p>}
  </form>
</div>
);
};

export default ForgotPassword;
