// import React from "react";
// import CommonNavbar from "../Shared/CommonNavbar";
// import { PCRoutes } from "../../constants";

// export default function PCNavbar() {
//   return (
//     <CommonNavbar
//       wrapperClass="left-navbar"
//       menuItemClass="nav-button"
//       routes={PCRoutes}
//     />
//   );
// }


import React, { useState, useEffect } from "react";
import CommonNavbar from "../Shared/CommonNavbar";
import "../../css/global-styles.css";
import { useUser } from "../../UserContext";
import axios from "axios";
import { PCRoutes } from "../../constants";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

interface UserProfile {
  firstname: string;
  lastname: string;
  email: string;
  phone?: string;
  id?: string;
}
interface PCNavbarProps {
  wrapperClass?: string;
  menuItemClass?: string;
  isSubMenu?: boolean;
}
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};
const PCNavbar: React.FC<PCNavbarProps> = ({  isSubMenu, wrapperClass, menuItemClass }) => {
  const { userId } = useUser();
  const [user, setUser] = useState(null);
  const [showProfile, setShowProfile] = useState(false);
  const [showEditProfile, setShowEditProfile] = useState(false);
  const [editProfile, setEditProfile] = useState({
    id:"",
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
  });
  const [profile, setProfile] = useState<UserProfile>({
    firstname: "",
    lastname: "",
    email: "",
    phone:"",
    // phone and id are optional, so they don't need to be initialized
  });


  useEffect(() => {
    axios.post("https://rxr9916.uta.cloud/profile.php", JSON.stringify({ user_id: userId }), {
  headers: {
    'Content-Type': 'application/json',
  }
})
      .then((response) => {
        if (response.data.error) {
          console.error(response.data.error);
        } else {
          setUser(response.data);
          setProfile({
            firstname: response.data.fname,
            lastname: response.data.lname,
            email: response.data.email,
            phone: response.data.phone_number,
            id: response.data.id,
          });
        }
      })
      .catch((error) => {
        console.error("Error fetching user details: ", error);
      });
  }, [userId]);

  useEffect(() => {
    setEditProfile({
      id:profile.id,
      firstname: profile.firstname,
      lastname: profile.lastname,
      email: profile.email,
      phone: profile.phone || "",
    });
  }, [profile]);

  const handleEditProfileSubmit = (event) => {
    event.preventDefault();

    // Create an object with the form data
    const data = {
        id: editProfile.id,
        firstname: editProfile.firstname,
        lastname: editProfile.lastname,
        email: editProfile.email,
        phone: editProfile.phone,
    };

    fetch("https://rxr9916.uta.cloud/updateProfile.php", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
      credentials: "include",
    })
    .then((response) => response.json())
    .then((data) => {
      if (data.error) {
        console.error("Error updating profile:", data.error);
      } else {
        // Update the user profile with the edited data
        setProfile({
          id: editProfile.id,
          firstname: editProfile.firstname,
          lastname: editProfile.lastname,
          email: editProfile.email,
          phone: editProfile.phone,
        });

        // Close the edit profile modal
        setShowEditProfile(false);
      }
    })
    .catch((error) => {
      console.error("There was an error updating the profile:", error);
    });
};



  return (

    <div className="parent-leftnavbar">
      <button type="submit" onClick={() => setShowProfile(!showProfile)}>
        View Profile
      </button>
      <button type="submit" onClick={() => setShowEditProfile(true)}>
        Edit Profile
      </button>
      {showProfile && (
        <Modal
        open={showProfile}
        onClose={() => setShowProfile(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Profile Details
          </Typography>
          <Box sx={{ mt: 2 }}>
            <Typography variant="body1">User ID: {profile.id}</Typography>
            <Typography variant="body1">First Name: {profile.firstname}</Typography>
            <Typography variant="body1">Last Name: {profile.lastname}</Typography>
            <Typography variant="body1">Email: {profile.email}</Typography>
            <Typography variant="body1">Phone Number: {profile.phone}</Typography>
            {/* Include any other profile details you wish to display */}
          </Box>
          <Button
            onClick={() => setShowProfile(false)}
            variant="outlined"
            sx={{ mt: 2 }}
          >
            Close
          </Button>
        </Box>
      </Modal>
        
      )}
      {showEditProfile && (
        // <div
        //   style={{
        //     position: "fixed",
        //     top: 0,
        //     left: 0,
        //     right: 0,
        //     bottom: 0,
        //     backgroundColor: "rgba(0, 0, 0, 0.7)",
        //     display: "flex",
        //     alignItems: "center",
        //     justifyContent: "center",
        //     zIndex: 1000,
        //   }}
        // >
        //   <div
        //     style={{
        //       backgroundColor: "#ccc",
        //       padding: "20px",
        //       borderRadius: "10px",
        //       width: "90%",
        //       maxWidth: "500px",
        //       boxShadow: "0 5px 15px rgba(0, 0, 0, 0.3)",
        //     }}
        //   >
        //     <div
        //       style={{
        //         display: "flex",
        //         justifyContent: "flex-end",
        //       }}
        //     >
        //       <button
        //         onClick={() => setShowEditProfile(false)}
        //         style={{
        //           background: "white",
        //           borderRadius: "5px",
        //           border: "none",
        //           fontWeight: "bold",
        //           cursor: "pointer",
        //         }}
        //       >
        //         X
        //       </button>
        //     </div>
        //     <form onSubmit={handleEditProfileSubmit}>
        //       <div
        //         style={{
        //           display: "flex",
        //           flexDirection: "column",
        //           gap: "10px",
        //           alignItems: 'center'
        //         }}
        //       >
        //          <input
        //       type="text"
        //       value={editProfile.id }
        //       readOnly
        //       onChange={(e) => setEditProfile({ ...editProfile, id: e.target.value })}
        //       placeholder="User ID"
        //       style={{ borderRadius: "10px", width: '80%', height: '40px' }}
        //     />
        //         {/* Input fields for edit form */}
        //         <input type="text" value={editProfile.firstname} onChange={(e) => setEditProfile({ ...editProfile, firstname: e.target.value })} placeholder=" Edit First Name"
        //           style={{ borderRadius: "10px", width: '80%', height: '40px' }} />
        //         <input type="text" value={editProfile.lastname} onChange={(e) => setEditProfile({ ...editProfile, lastname: e.target.value })} placeholder=" Edit Last Name"
        //           style={{ borderRadius: "10px", width: '80%', height: '40px' }} />
        //         <input type="email" value={editProfile.email} onChange={(e) => setEditProfile({ ...editProfile, email: e.target.value })} readOnly placeholder=" Edit Email"
        //           style={{ borderRadius: "10px", width: '80%', height: '40px' }} />
        //         <input type="tel" value={editProfile.phone} onChange={(e) => setEditProfile({ ...editProfile, phone: e.target.value })} placeholder=" Edit Phone Number"
        //           style={{ borderRadius: "10px", width: '80%', height: '40px' }} />
        //         <button type="submit" style={{ width: '40%', backgroundColor: 'whitesmoke', color: 'black' }}>Save Changes</button>
        //       </div>
        //     </form>
        //   </div>
        // </div>
        <Modal
      open={showEditProfile}
      onClose={() => setShowEditProfile(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Edit Profile
        </Typography>
        <Box
          component="form"
          noValidate
          autoComplete="off"
          onSubmit={handleEditProfileSubmit}
          sx={{ mt: 1 }}
        >
          {/* Input fields for edit form */}
          <TextField
            margin="normal"
            required
            fullWidth
            label="First Name"
            value={editProfile.firstname}
            onChange={(e) => setEditProfile({ ...editProfile, firstname: e.target.value })}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            label="Last Name"
            value={editProfile.lastname}
            onChange={(e) => setEditProfile({ ...editProfile, lastname: e.target.value })}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            label="Email"
            value={editProfile.email}
            onChange={(e) => setEditProfile({ ...editProfile, email: e.target.value })}
            disabled
          />
          <TextField
            margin="normal"
            required
            fullWidth
            label="Phone Number"
            value={editProfile.phone}
            onChange={(e) => setEditProfile({ ...editProfile, phone: e.target.value })}
          />
          <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
            Save Changes
          </Button>
        </Box>
      </Box>
    </Modal>
      )}

      <CommonNavbar
         isSubMenu={false}
         
      wrapperClass="left-navbar"
          menuItemClass="nav-button"
        routes={PCRoutes}
      />
    </div>
  );
}

export default PCNavbar;