import React, { useState, useEffect } from 'react';
import ChatMessages from './ChatMessages';
import axios from 'axios';

function ChatContent({ selectedRole, usersWithRole, selectedUser, formData, handleRoleChange, handleSelectedUser, handleFormChange, handleSubmit, chatRecord, userId ,setChatRecord}) {

  const [searchTerm, setSearchTerm] = useState('');
  useEffect(() => {
    // Check if a user is selected
    console.log(selectedRole, usersWithRole, selectedUser)
    if (selectedUser && Array.isArray(usersWithRole)) {
      // Make a POST request to fetch_chat_history.php with sender and receiver parameters in the request body
      axios
        .post('https://rxr9916.uta.cloud/fetch_chat_history.php', {
          sender_id: userId,
          receiver_id: selectedUser,
        })
        .then(response => {
          // Handle the response, e.g., update the chatRecord state with the fetched data
          console.log('Chat history fetched:', response.data);
          setChatRecord(response.data.chat_messages);

        })
        .catch(error => {
          console.error('Error fetching chat history:', error);
        });
    }
  }, [selectedUser, userId]); // Run the effect when selectedUser or userId changes
  


  
  return (
    <div className="c1content">
      {/* ... (rest of the component code) */}
      <div className="searchcontent">
        <form className="feedback-form" onSubmit={handleSubmit}>
          <input type="hidden" name="side" value="student" />
          <fieldset style={{backgroundColor:'#9da99d', border:'1px solid #ccc',}} id="signinfo" className="filldetails for_phone">
            <legend>CHAT</legend>
            <div id="signinfohorizontal">
            <select
              id="description"
              name="role"
              className="chatdropdown"
              value={selectedRole}
              onChange={handleRoleChange}
              placeholder='Select'
            >
              <option value="">Choose a Role</option>
              <option value="student">Student</option>
              <option value="instructor">Instructor</option>
              <option value="admin">Admin</option>
              <option value="pc">Program Coordinator</option>
              <option value="qa">Quality Assurance Officer</option>

            </select>
            </div>
            <br />
            <div id="signinfohorizontal">
            <select id="description" name="receiver" onChange={handleSelectedUser}>
  {Array.isArray(usersWithRole) && usersWithRole
    .filter(user => user.id !== userId) // Adding filter to exclude the current user
    .map((result) => (
      <option value={result.id} key={result.id}>
        {result.fname} 
      </option>
    ))
  }
</select>

            </div>
            <br />
            <div id="signinfohorizontal">
            <textarea
              style={{
                height:'10vh'
              }}
              id="description"
              name="message"
              placeholder="Message..."
              value={formData.message}
              onChange={handleFormChange}
            ></textarea>
            </div>
            
            <br />
            <button style={{width:'25vw', alignSelf:'center'}}type="submit">Submit</button>
            <br />
            
          </fieldset>
        </form>
      </div>
      <br />
      <br />
      <ChatMessages chatRecord={chatRecord} userId={userId} senderName={userId} receiverName={selectedUser}/>
    </div>
  );
}

export default ChatContent;
