import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import AdminNavbar from "./AdminNavbar";
import { adminRoutes } from "../../constants";
import CommonNavbar from "../Shared/CommonNavbar";
import axios from 'axios'; // Assuming you're using axios for API calls

const UserLogs = () => {
  const { id } = useParams(); // Access the user ID from the URL
  const [userData, setUserData] = useState(null); // State to store user data

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(`https://rxr9916.uta.cloud/monitor.php?userId=${id}`);
        setUserData(response.data);
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };
  
    fetchUserData();
  }, [id]);

  const clearUserLogs = async () => {
    try {
      const response = await axios.get(`[Your Server URL]/clearlogs.php?userId=${id}`);
      alert(response.data.success || response.data.error);
      setUserData([]); // Clear the local state
    } catch (error) {
      console.error('Error clearing user logs:', error);
      alert('Failed to clear logs');
    }
  };
   // Dependency array ensures this runs when 'id' changes

  // Ensure that the return statement is properly within the function body
  return (
    <div className="pcrpage_container">
      <div className="main-container">
        <CommonNavbar
          wrapperClass="left-navbar"
          menuItemClass="nav-button"
          routes={adminRoutes}
        />
        <div className="right-content">
          <div className="PC_container-card-grid">
            <div className="card-row-enquiries">
              <h3>User Logs for ID: {id}</h3>
              <br />
              <br />
              
                {userData ? (
                  userData.map((activity, index) => (
                    <div key={index} className="card-enquiries">
                      <div className="student-info-enquiries">
                      <p>User: {activity.fname} {activity.lname}</p>
                      <p>Time: {activity.activity_timestamp}</p>
                      <p>Type: {activity.activity_type}</p>
                      <p>IP Address: {activity.user_ip}</p>
                      {/* Add additional activity details here if needed */}
                      </div>
                    </div>
                  ))
                ) : (
                  <p>Loading user data...</p>
                )}
              <button type="submit" onClick={clearUserLogs}>Clear User Logs</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserLogs;
