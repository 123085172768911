import React, { useState, useEffect } from 'react';
import axios from 'axios';

interface FeedbackResult {
  feedback_id: string;
  sentiment_score: string;
  sentiment: string;
  sentiment_magnitude: string;
}

const FeedbackAnalysis = () => {
  const [feedbackResults, setFeedbackResults] = useState<FeedbackResult[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchFeedbackAnalysis = async () => {
      try {
        const response = await axios.get('https://rxr9916.uta.cloud/feedbackAnalysis.php');
        console.log('API Response 123:', response.data);
        if (Array.isArray(response.data)) {
         
          setFeedbackResults(response.data);
        } else {
          // Handle the case where data is not an array
          console.error('Expected an array response, received:', response.data);
        }
      } catch (error) {
        console.error('Error fetching feedback analysis:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchFeedbackAnalysis();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      {/* <h2>Feedback Analysis Results</h2> */}
      <table
       style={{
        marginLeft: "70px",
        width: "80%",
        borderCollapse: "collapse",
        marginTop: "20px",
      }}
      >
      <thead style={{ backgroundColor: "#4CAF50", color: "white" }}>
          <tr>
             <th style={{ border: "1px solid #ddd", padding: "8px" }}>Feedback ID</th>
             <th style={{ border: "1px solid #ddd", padding: "8px" }}>Feedback Score</th>
             <th style={{ border: "1px solid #ddd", padding: "8px" }}>Feedback Magnitude</th>
             <th style={{ border: "1px solid #ddd", padding: "8px" }}>Sentiment</th>
          </tr>
        </thead>
        <tbody>
          {feedbackResults.map((result) => (
           <tr key={result.feedback_id} style={{
            backgroundColor: +result.feedback_id % 2 === 0 ? "#f2f2f2" : "white",
          }}>
               <td style={{ border: "1px solid #ddd", padding: "8px" }}>{result.feedback_id}</td>
               <td style={{ border: "1px solid #ddd", padding: "8px" }}>{result.sentiment_score}</td>
               <td style={{ border: "1px solid #ddd", padding: "8px" }}>{result.sentiment_magnitude}</td>
               <td style={{ border: "1px solid #ddd", padding: "8px",
              color: result.sentiment === 'positive' ? 'blue' : result.sentiment === 'negative' ? 'red' : 'orange',
               }}>{result.sentiment}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default FeedbackAnalysis;