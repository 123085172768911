import React, { useState, useEffect } from "react";
import InstructorNavbar from "./InstructorNavbar";
import axios from "axios";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useUser } from "../../UserContext";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};
const style2 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};
export default function InstructorDashboard() {
  const [courseGrades, setCourseGrades] = useState([]);
  const [open, setOpen] = useState(false);
  const { userId, setUserId } = useUser();
  const [courses, setCourses] = useState([]);
  const [instructorName, setInstructorName] = useState("");
  const [courseName, setCourseName] = useState("");
  const [courseContent, setCourseContent] = useState("");
  const [openEdit, setOpenEdit] = useState(false);
  const [selectedCourseId, setSelectedCourseId] = useState("");
  const [updatedCourseName, setUpdatedCourseName] = useState("");
  const [selectedTerm, setSelectedTerm] = useState("");
  const [selectedCourseTerm, setSelectedCourseTerm] = useState("");

  useEffect(() => {
    if (userId) {
      axios
        .post("https://rxr9916.uta.cloud/profile.php", { user_id: userId })
        .then((response) => {
          // Check if there is an error in the response
          if (response.data.error) {
            console.error("Error:", response.data.error);
          } else {
            // Assuming the response data has the user's first and last name
            const { fname, lname } = response.data;
            setInstructorName(`${fname} ${lname}`);
          }
        })
        .catch((error) => {
          console.error("Error fetching instructor information:", error);
        });
    }
  }, [userId]); // This effect runs when the userId changes.
  //const [courses, setCourses] = useState([]);

  useEffect(() => {
    // Assuming you fetch the instructor's ID and set it in the userId state
    if (userId) {
      axios
        .post("https://rxr9916.uta.cloud/coursesinstructor.php", {
          instructorName: instructorName,
        })
        .then((response) => {
          if (Array.isArray(response.data)) {
            setCourses(response.data);
          } else if (response.data.error) {
            console.error("Error:", response.data.error);
          } else {
            console.error("Unexpected response:", response.data);
          }
        })
        .catch((error) => {
          console.error("Error fetching courses:", error);
        });
    }
  }, [userId, instructorName]);
  const handleCourseNameChange = (e) => {
    setUpdatedCourseName(e.target.value);
  };

  const handleCourseContentChange = (e) => {
    setCourseContent(e.target.value);
  };
  const handleTermChange = (e) => {
    setSelectedCourseTerm(e.target.value);
};

  const handleAddCourse = async () => {
    try {
      const response = await axios.post(
        "https://rxr9916.uta.cloud/addinstcourse.php",
        {
          courseName,
          courseContent,
          instructorName,
          term: selectedTerm,
        }
      );

      if (response.data.success) {
        // Handle success (e.g., close modal, fetch updated list of courses)
        handleClose();
        window.location.reload();
      } else {
        // Handle error
        console.error("Failed to add course:", response.data.message);
      }
    } catch (error) {
      console.error("Error adding course:", error);
    }
  };

  const handleModalOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  //for edit
  const handleOpenEditModal = () => setOpenEdit(true);
  const handleCloseEditModal = () => setOpenEdit(false);

  const handleCourseSelect = (e) => {
    // console.log(e);
    // console.log("Dropdown Selected Value:", e.target.value);  // Log the selected value from the dropdown
    // Find the course with the selected ID
    const selectedCourse = courses.find(
      (course) => course.cid === parseInt(e.target.value, 10)
    );
    
    console.log("selectedCourse: ",selectedCourse);
    if (selectedCourse) {
      // If the course is found, update the states
      setSelectedCourseId(selectedCourse.cid);
      console.log("Found Selected Course:", selectedCourse);
      setUpdatedCourseName(selectedCourse.course_name);
      setSelectedCourseTerm(selectedCourse.term);
      if (selectedCourse) {
        setSelectedCourseId(selectedCourse.cid);

        let formData = new FormData();
        formData.append('courseId', selectedCourse.cid);

        axios.post("https://rxr9916.uta.cloud/editcoursecontent.php", formData)
            .then(response => {
                if (response.data.success) {
                    setCourseContent(response.data.courseContent);
                } else {
                    console.error("Error fetching course content:", response.data.message);
                }
            })
            .catch(error => {
                console.error("Error fetching course content:", error);
            });
    } else {
        setSelectedCourseId("");
        setCourseContent("");
    }
      
    } else {
      // If no course is found, reset the states
      setSelectedCourseId("");
      setUpdatedCourseName("");
      setSelectedCourseTerm("");
    }
    
  };

  useEffect(() => {
    console.log("Updated Selected Course ID:", selectedCourseId);
}, [selectedCourseId]);


  const handleCourseNameChange1 = (e) => {
    setUpdatedCourseName(e.target.value);
  };

  const handleEditCourseSubmit = async () => {
    try {
      const payload = {
        courseId: selectedCourseId,
        courseName: updatedCourseName,
        courseContent: courseContent,
        term: selectedTerm,
      };

      const response = await axios.post(
        "https://rxr9916.uta.cloud/editCourse.php",
        payload
      );

      if (response.data.success) {
        handleCloseEditModal();
        // Optional: Handle successful update (e.g., display a message or refresh data)
        window.location.reload(); // Uncomment this if you want to reload the page
      } else {
        console.error("Failed to edit course:", response.data.message);
        // Optional: Handle failure (e.g., display an error message)
      }
    } catch (error) {
      console.error("Error editing course:", error);
      // Optional: Handle network or other errors (e.g., display an error message)
    }
};

  const handleDeleteCourseSubmit = async () => {
    try {
      const response = await axios.post(
        "https://rxr9916.uta.cloud/deleteinstructorcourse.php",
        {
          courseId: selectedCourseId,
          courseName: updatedCourseName,
          instructorName: instructorName,
        }
      );

      if (response.data.success) {
        handleCloseEditModal();
        window.location.reload();
      } else {
        console.error("Failed to edit course:", response.data.message);
      }
    } catch (error) {
      console.error("Error editing course:", error);
    }
  };
  const handleCourseSelect2 = async (courseId) => {
    try {
      const response = await axios.post(
        "https://rxr9916.uta.cloud/fetchcoursegradeinfo.php",
        { courseId }
      );
      if (response.data && Array.isArray(response.data)) {
        setCourseGrades(response.data);
      } else {
        console.error("Error fetching course grade info:", response.data);
      }
    } catch (error) {
      console.error("Error fetching course grade info:", error);
    }
  };
  return (
    <div className="page-container">
      {/* MAIN CONTENT = NO HEADER AND FOOTER */}
      <div className="main-container">
        {/* LEFT NAVBAR */}
        <InstructorNavbar
          wrapperClass="left-navbar"
          menuItemClass="nav-button"
        />

        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignContent: "flex-end",
              marginRight: "20px",
            }}
          >
            <button
              type="submit"
              style={{ backgroundColor: "green" }}
              onClick={handleModalOpen}
            >
              Add course
            </button>
            <br />
            <button type="submit" onClick={handleOpenEditModal}>
              Edit Course
            </button>
          </div>
          <>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <IconButton onClick={handleClose}>
                    <CloseIcon />
                  </IconButton>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    marginTop: 10,
                  }}
                >
                  <TextField
                    fullWidth
                    placeholder="Course Name"
                    value={courseName}
                    onChange={(e) => setCourseName(e.target.value)}
                    variant="outlined"
                    margin="normal"
                  />
                  <TextField
                    fullWidth
                    placeholder="Course Content"
                    value={courseContent}
                    onChange={handleCourseContentChange}
                    variant="outlined"
                    margin="normal"
                  />
                  <FormControl fullWidth>
                    <InputLabel id="term-select-label">Term</InputLabel>
                    <Select
                      labelId="term-select-label"
                      id="term-select"
                      value={selectedTerm}
                      onChange={(e) => setSelectedTerm(e.target.value)}
                    >
                      <MenuItem value="Fall 2023">Fall 2023</MenuItem>
                      <MenuItem value="Spring 2024">Spring 2024</MenuItem>
                      {/* Add other terms as needed */}
                    </Select>
                  </FormControl>
                  <Button
                    variant="contained"
                    sx={{ alignSelf: "center", mt: 2 }}
                    onClick={handleAddCourse}
                  >
                    Add Course
                  </Button>
                </div>
              </Box>
            </Modal>
          </>

          <Modal open={openEdit} onClose={handleCloseEditModal}>
            <Box sx={style2}>
              <IconButton
                onClick={handleCloseEditModal}
                sx={{ position: "absolute", top: 8, right: 8 }}
              >
                <CloseIcon />
              </IconButton>
              <h2>Edit Course</h2>
              <FormControl fullWidth margin="normal">
                <InputLabel id="course-select-label">Course</InputLabel>
                <Select
                  labelId="course-select-label"
                  value={selectedCourseId}
                  label="Course"
                  onChange={handleCourseSelect}
                >
                  {courses.map((course) => (
                    <MenuItem key={course.cid} value={course.cid}>
                      {course.course_name}
                    </MenuItem>
                  ))}
                </Select>

                {/* <select onChange={handleCourseSelect}>
                  {courses.map((course) => (
                    <option key={course.cid} value={course.cid}>
                      {course.course_name}
                    </option>
                  ))}
                </select> */}
              </FormControl>
              <TextField
                fullWidth
                margin="normal"
                placeholder="New course name"
                value={updatedCourseName}
                inputProps={{ readOnly: true }}
                onChange={handleCourseNameChange1}
              />
              <TextField
  fullWidth
  margin="normal"
  placeholder="Course Content"
  value={courseContent}
  onChange={(e) => setCourseContent(e.target.value)}
/>


              <Button
                variant="contained"
                color="primary"
                sx={{ mt: 2, mr: 1 }}
                onClick={handleEditCourseSubmit}
              >
                Save Changes
              </Button>
              <Button
                variant="contained"
                color="secondary"
                sx={{ mt: 2 }}
                onClick={handleDeleteCourseSubmit}
              >
                Delete
              </Button>
            </Box>
          </Modal>

          <div className="content">
            {/* changedddddddddddddddddddddd */}
            {courses.map((course, index) => (
              <div
                className="course-box"
                key={course.cid}
                onClick={() => handleCourseSelect2(course.cid)}
              >
                <a href="#">
                  {/* Course {index + 1}
                  <br />
                  <br />
                  <br /> */}
                  {course.course_name}
                </a>
              </div>
            ))}
            {/* changedddddddddddddddddddddd */}
            {courseGrades.length > 0 && (
              <table
                style={{
                  width: "80%",
                  borderCollapse: "collapse",
                  marginTop: "20px",
                }}
              >
                <thead style={{ backgroundColor: "#4CAF50", color: "white" }}>
                  <tr>
                    <th style={{ border: "1px solid #ddd", padding: "8px" }}>
                      Student ID
                    </th>
                    <th style={{ border: "1px solid #ddd", padding: "8px" }}>
                      First Name
                    </th>
                    <th style={{ border: "1px solid #ddd", padding: "8px" }}>
                      Last Name
                    </th>
                    <th style={{ border: "1px solid #ddd", padding: "8px" }}>
                      Email
                    </th>
                    <th style={{ border: "1px solid #ddd", padding: "8px" }}>
                      Grade
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {courseGrades.map((grade, idx) => (
                    <tr
                      key={idx}
                      style={{
                        backgroundColor: idx % 2 === 0 ? "#f2f2f2" : "white",
                      }}
                    >
                      <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                        {grade.student_id}
                      </td>
                      <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                        {" "}
                        {grade.fname}
                      </td>
                      <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                        {grade.lname}
                      </td>
                      <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                        {grade.email}
                      </td>
                      <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                        {grade.grade}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
