import React, { useState, useEffect } from "react";
import axios from 'axios';
import StudentNavbar from "./StudentNavbar";
import "../../css/global-styles.css";
import { useUser } from '../../UserContext';
function StudentCourse() {
  const [courses, setCourses] = useState([]);
  const [selectedCourses, setSelectedCourses] = useState([]);
  const [enrolledCourses, setEnrolledCourses] = useState([]);
  const [terms, setTerms] = useState([]);  // New state for terms
  const [selectedTerm, setSelectedTerm] = useState('');
  const { userId } = useUser();

  useEffect(() => {
    // Fetch the list of available courses
    axios.get('https://rxr9916.uta.cloud/courseslist.php')
      .then(response => setCourses(response.data))
      .catch(error => console.error("Error fetching courses:", error));


      axios.get('https://rxr9916.uta.cloud/term.php')
  .then(response => {
    console.log(response.data); // Add this line
    setTerms(response.data);
  })
  .catch(error => console.error("Error fetching terms:", error));

  
    // Fetch the list of enrolled courses
    axios.post('https://rxr9916.uta.cloud/enrolledCourses.php', { student_id: userId })
      .then(response => {
        // Ensure that response.data is an array before setting the state
        if (Array.isArray(response.data)) {
          setEnrolledCourses(response.data);
        } else {
          //console.error("Unexpected response structure:", response.data);
          // Set enrolledCourses to an empty array as a fallback
          setEnrolledCourses([]);
        }
      })
      .catch(error => console.error("Error fetching enrolled courses:", error));
  }, [userId]);
  
  const handleTermChange = (event) => {
    const term = event.target.value;
    setSelectedTerm(term);
    
    // Fetch courses for the selected term
    axios.get(`https://rxr9916.uta.cloud/courseslist.php?term=${term}`)
         .then(response => {
             setCourses(response.data); // Update courses state with fetched data
         })
         .catch(error => console.error("Error fetching courses:", error));
};


  const handleCheckboxChange = (courseId) => {
    setSelectedCourses(prevSelected => {
      if (prevSelected.includes(courseId)) {
        const updatedSelection = prevSelected.filter(id => id !== courseId);
        console.log("Deselected:", updatedSelection);
        return updatedSelection;
      } else {
        const updatedSelection = [...prevSelected, courseId];
        console.log("Selected:", updatedSelection);
        return updatedSelection;
      }
    });
  };
  

// ...

const handleEnroll = (e) => {
  e.preventDefault();

  if (selectedCourses.length > 3) {
    alert("You can select up to 3 courses only.");
    return;
  }

  const selectedCourseIds = selectedCourses.map(courseId => courseId).join(',');
  const selectedCourseNames = selectedCourses.map(courseId => {
    const course = courses.find(course => course.cid === courseId);
    return course ? course.course_name : '';
  }).join(',');
  
  console.log("selectedCourseNames:", selectedCourseNames);
  

  // Update the "enroll_courses" table with the selected courses
  const formData = new FormData();
  formData.append('student_id', userId);
  formData.append('selected_courses', selectedCourseIds);
  formData.append('selected_course_names', selectedCourseNames);
  formData.append('selected_term', selectedTerm);

  axios.post('https://rxr9916.uta.cloud/enrollCourses.php', formData)
    .then(response => {
      alert("Courses enrolled successfully!");
      setEnrolledCourses(response.data);
      setSelectedCourses([]);
    })
    .catch(error => console.error("Error enrolling courses:", error));
};

// ...


  

  return (
    <div className="scpage-container">
      <div className="scmain-container">
        <StudentNavbar wrapperClass="left-navbar" menuItemClass="nav-button" />
        <div className="sccontent">
          
          <div className="newenroll">
            <br/>
            <h2>Enroll New Courses</h2>
            
            {/* <small className="note">
              Select up to 3 new courses to enroll for next semester
            </small> */}
            {/* <p>Logged in as: {userId}</p> */}
            <form id="newenrolled" onSubmit={handleEnroll}>
            <div className="term-selector">
        <label htmlFor="term-select">Choose Term: </label>
        <select style={{ width: '200px', height: '30px', borderRadius:'5px' }} id="term-select" value={selectedTerm} onChange={handleTermChange}>
          {terms.map(term => (
            <option key={term} value={term}>{term}</option>
          ))}
        </select>
        
      </div>
      <br />
              {courses.map(course => (
                <div key={course.cid} className="custom-checkbox">
                  <input
                    id={`course_${course.cid}`}
                    name={`course_${course.cid}`}
                    type="checkbox"
                    value={course.cid}
                    checked={selectedCourses.includes(course.cid)}
                    onChange={() => handleCheckboxChange(course.cid)}
                  />
                  <label htmlFor={`course_${course.cid}`}>{course.course_name}</label>
                </div>
              ))}
              <br />
              <button type="submit">Enroll Courses</button> 
              <br />
              <br />
            </form>
          </div>
        </div>
      </div>
      
    </div>
  );
}

export default StudentCourse;
