import React from "react";
import AdminNavbar from "./AdminNavbar";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import axios from "axios";
import { UserContext } from "../../UserContext";
// Adjust the path as necessary

class ManageUser extends React.Component {
  constructor(props) {
    super(props);
  }

  state = {
    data: [],
    users: [],
    url: "https://rxr9916.uta.cloud/deleteuser.php?q=",
    showModal: false,
    userToEdit: {
      fname: "",
      lname: "",
      email: "",
      role: "",
      id: 0,
    },
  };

  openEditModal = (user) => {
    this.setState({
      showModal: true,
      userToEdit: {
        id: user.id,
        fname: user.fname,
        lname: user.lname,
        email: user.email,
        role: user.role,
      },
    });
  };

  closeEditModal = () => {
    this.setState({
      showModal: false,
      userToEdit: {
        fname: "",
        lname: "",
        email: "",
        role: "",
        id: 0,
      },
    });
  };

  fetchUsers = () => {
    axios
      .get("https://rxr9916.uta.cloud/users.php")
      .then((response) => {
        this.setState({ data: response.data });
      })
      .catch((error) => {
        console.error("Error fetching users:", error);
      });
  };
  handleDelete = (userId) => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this user?"
    );
    if (confirmed) {
      axios
        .post("https://rxr9916.uta.cloud/deleteuser.php", {
          q: userId,
        })

        .then((response) => {
          if (response.data.success) {
            // If the deletion is successful, fetch the updated list of users
            this.fetchUsers();
          } else {
            console.error("Delete failed:", response.data.message);
          }
        })
        .catch((error) => {
          console.error("Error deleting user:", error);
        });
    }
  };
  handleEditSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    formData.append("id", this.state.userToEdit.id.toString());

    axios({
      method: "post",
      url: "https://rxr9916.uta.cloud/editUser.php",
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((response) => {
        if (response.data.success) {
          this.fetchUsers();
          this.setState({
            showModal: false,
            userToEdit: {
              fname: "",
              lname: "",
              email: "",
              role: "",
              id: 0,
            },
          });
        } else {
          console.error("Update failed:", response.data.message);
        }
      })
      .catch((error) => {
        console.error("Error submitting form:", error);
      });
  };

  componentDidMount() {
    axios
      .get("https://rxr9916.uta.cloud/fetchinstructor.php")
      .then((res) => {
        if (res.data && Array.isArray(res.data.instructors)) {
          this.setState({ users: res.data.instructors });
        } else {
          console.error(
            "Expected an object with an instructors array, but got:",
            res.data
          );
          this.setState({ users: [] });
        }
      })
      .catch((error) => {
        console.error("An error occurred while fetching users:", error);
        this.setState({ users: [] });
      });

    this.fetchUsers();
  }

  render() {
    const { data, showModal, userToEdit } = this.state;
    const style = {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: 400,
      bgcolor: "background.paper",
      boxShadow: 24,
      p: 4,
      borderRadius: "20px",
    };
    return (
      <div className="amcpage_container">
        <div className="amcmain-container">
          <AdminNavbar wrapperClass="left-navbar" menuItemClass="nav-button" />
          <Modal
            open={showModal}
            onClose={this.closeEditModal}
            aria-labelledby="edit-user-modal-title"
            aria-describedby="edit-user-modal-description"
          >
            <Box sx={style}>
              <Typography
                id="edit-user-modal-title"
                variant="h6"
                component="h2"
              >
                Edit User
              </Typography>
              <Box
                component="form"
                noValidate
                autoComplete="off"
                onSubmit={this.handleEditSubmit}
              >
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="fname"
                  label="First Name"
                  name="fname"
                  value={userToEdit.fname}
                  onChange={(e) =>
                    this.setState({
                      userToEdit: {
                        ...userToEdit,
                        fname: e.target.value,
                      },
                    })
                  }
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="lname"
                  label="Last Name"
                  name="lname"
                  value={userToEdit.lname}
                  onChange={(e) =>
                    this.setState({
                      userToEdit: {
                        ...userToEdit,
                        lname: e.target.value,
                      },
                    })
                  }
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  value={userToEdit.email}
                  onChange={(e) =>
                    this.setState({
                      userToEdit: {
                        ...userToEdit,
                        email: e.target.value,
                      },
                    })
                  }
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="role"
                  label="Role"
                  name="role"
                  value={userToEdit.role}
                  onChange={(e) =>
                    this.setState({
                      userToEdit: {
                        ...userToEdit,
                        role: e.target.value,
                      },
                    })
                  }
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Save Changes
                </Button>
                <Button
                  fullWidth
                  variant="outlined"
                  sx={{ mt: 1, mb: 2 }}
                  onClick={this.closeEditModal}
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          </Modal>

          <div className="amccontent">
            <div className="form">
              <form
                id="admin_form"
                action="https://rxr9916.uta.cloud/adminAdd.php"
                method="post"
              >
                <fieldset id="admc">
                  <legend>Add a New User</legend>

                  <label htmlFor="role">Role</label>
                  <select id="role" name="role">
                    <option value="none">Choose Role</option>
                    <option value="student">Student</option>
                    <option value="instructor">Instructor</option>
                    <option value="pc">PC</option>
                    <option value="qa">QAO</option>
                  </select>

                  <label htmlFor="fname">First Name:</label>
                  <input id="fname" name="fname" type="text" required />

                  <label htmlFor="lname">Last Name:</label>
                  <input id="lname" name="lname" type="text" required />

                  <label htmlFor="email">Email:</label>
                  <input
                    id="email"
                    name="email"
                    type="email"
                    pattern="[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,}$"
                    required
                  />
                </fieldset>
                <br />
                <button type="submit">Add User</button>
              </form>
            </div>

            <UserContext.Consumer>
              {({ userId }) => (
                <div className="amctable">
                  <table className="amcgrades-table">
                    <thead>
                      <tr className="amcgrades-table-row">
                        <th className="amcgrades-table-header">First Name</th>
                        <th className="amcgrades-table-header">Last Name</th>
                        <th className="amcgrades-table-header">Email</th>
                        <th className="amcgrades-table-header">Role</th>
                        <th className="amcgrades-table-header">User ID</th>
                        <th className="amcgrades-table-header">Action</th>
                      </tr>
                    </thead>

                    <tbody>
                      {data.map((result) => (
                        <tr key={result.id} className="amcgrades-table-row">
                          <td>{result.fname}</td>
                          <td>{result.lname}</td>
                          <td>{result.email}</td>
                          <td>{result.role}</td>
                          <td>{result.id}</td>

                          <td>
                            {/* <button
                                disabled={result.id === userId}
                                type="submit"
                                onClick={() => this.openEditModal(result)}
                              >
                                Edit
                              </button>
                              <button
                                disabled={result.id === userId}
                                style={{ backgroundColor: "red" }}
                                type="submit"
                                onClick={() => this.handleDelete(result.id)}
                              >
                                Delete
                              </button> */}
                            {result.id !== userId && (
                              <button type="submit"
                                onClick={() => this.openEditModal(result)}
                              >
                                Edit
                              </button>
                            )}
                            {result.id !== userId && (
                              <button type="submit"
                                style={{ backgroundColor: "red" }}
                                onClick={() => this.handleDelete(result.id)}
                              >
                                Delete
                              </button>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <br />
                  <br />
                </div>
              )}
            </UserContext.Consumer>
          </div>
        </div>
        <br />
      </div>
    );
  }
}

export default ManageUser;
