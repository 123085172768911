import React, { useEffect, useState } from "react";
import "../../css/global-styles.css";
import { Link } from "react-router-dom";
import { studentRoutes } from "../../constants";
import StudentNavbar from "./StudentNavbar";
import { useUser } from "../../UserContext";
import axios from 'axios';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';

const DashboardCourseList = () => {
  const labelStyle = {
    color: '#007bff', // Set the text color to blue
    fontSize: '18px',
    textAlign: 'center',
    '&.MuiInputLabel-shrink': {
      transform: 'translate(-50%, 1px) scale(0.75)', // Center horizontally and adjust position
      textAlign: 'center', // Center the text
      maxWidth: '100%', // Prevent overflow
      left: '50%', // Center position
      transformOrigin: '50% -100%', // Ensure transform scales from the center
    }
  };
}
  const selectStyle = {
    
    backgroundColor: 'white',
    borderRadius: 1, // Rounded corners
    borderColor: '#007bff', // Border color
    '&:hover': {
      borderColor: '#007bff', // Border color on hover
      boxShadow: 2, // Box shadow on hover
    },
    '&.Mui-focused': {
      borderColor: '#007bff', // Border color when focused
    }
  };

function StudentExams() {

  const { userId, setUserId } = useUser();
  // setting global user id to state 
  const [studentId, setStudentId] = useState(userId);
  const [enrolledCourses, setEnrolledCourses] = useState([]);
  const [error, setError] = useState('');
  const [exams, setExams] = useState([]);
  const [selectedCourseId, setSelectedCourseId] = useState('');
  const [modules, setModules] = useState([]);
  const [term, setTerm] = useState(''); // State for selected term
  
  useEffect(() => {
    if (!userId ) {
      setError('User student data not logged in.');
      return;
    }

    // Make a POST request to fetch enrolled courses
    axios.post(`https://rxr9916.uta.cloud/get_courses.php`, { student_id: userId , selected_term:term })
      .then(response => {
        if (response.data.error) {
          setError(response.data.error);
        } else {
          setEnrolledCourses(response.data);
        }
      })
      .catch(error => {
        console.error("Error fetching data: ", error);
      });
  }, [userId,term]);
  const handleTermChange = (event) => {
    setTerm(event.target.value);
    // Optionally, clear existing courses or modules when term changes
    setEnrolledCourses([]);
    setModules([]);
  };

  const handleCourseChange = (event) => {
    const courseId = event.target.value;
    setSelectedCourseId(courseId);
    setModules([]); // Clear modules when changing course
    if (courseId) {
      // Make a POST request to fetch modules for the selected course
      axios.post(`https://rxr9916.uta.cloud/get_course_modules.php`, { course_id: courseId })
        .then(response => {
          // handle response
          if (response.data.error) {
            setError(response.data.error);
          } else {
            setModules(response.data.modules || []);
          }
        })
        .catch(error => {
          // handle error
          console.error("Error fetching modules info: ", error);
        });
    }
  };

 
  
  return (
    <div className="sepage-container">
      <div className="semain-container">

        <StudentNavbar wrapperClass="left-navbar" menuItemClass="nav-button" />
       
        <div className="secontent">
          
        <div className="content">
        <div style={{width:'-webkit-fill-available',display:"flex", flexDirection:"column", alignItems:"center"}}>
        <div className="term-selector">
        <Select
            labelId="termSelect-label"
            id="termSelect"
            value={term}
            onChange={handleTermChange}
            style={{ width: '200px', height: '40px', backgroundColor:'whitesmoke' }}
            displayEmpty
          >
            <MenuItem value="">Select a term</MenuItem>
            <MenuItem value="Fall 2023">Fall 2023</MenuItem>
            <MenuItem value="Spring 2024">Spring 2024</MenuItem>
          </Select>
          </div>
          <br/>
          {error ? (
            <p>{error}</p>
          ) : (
            <Select
            labelId="courseSelect-label"
            id=""
            value={selectedCourseId}
            onChange={handleCourseChange}
            style={{ width: '200px', height: '40px', backgroundColor:'whitesmoke' }}
            displayEmpty
          >
            <MenuItem value=""><em>Select a course</em></MenuItem>
            {enrolledCourses.map(course => (
              <MenuItem key={course.ecid} value={course.ecid}>
                {course.course_name}
              </MenuItem>
            ))}
          </Select>
          )}
          <br/>
        </div>
        </div>
        
        <div id="modules" style={{ display: "flex", flexWrap: "wrap", flexDirection: 'column', }}>
            {modules.length > 0 ? modules.map((module, index) => (
              <div className="module-detail" key={index}>
                <h3>{module.module_name}</h3>
                <ul>
                  <li>Module ID: {module.module_id}</li>
                  <li>Course ID: {module.course_id}</li>
                  <li>Module Title: {module.module_title}</li>
                  <div style={{display:'flex'}}>
                  <a href='{module.total_marks}' style={{color:'blue'}} ><li>Click Here For Module! </li></a>
                  {/* Display other module information here */}
                  </div>
                </ul>
              </div>
            )) : <p></p>}
          </div>

        </div>
      </div>
    </div>
  );
}

export default StudentExams;