import { studentRoutes } from "../../constants";
import StudentNavbar from "./StudentNavbar";
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useUser } from "../../UserContext";
import { Link } from "react-router-dom";
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';


const DashboardCourseList = () => {
  const labelStyle = {
    color: '#007bff', // Set the text color to blue
    fontSize: '18px',
    textAlign: 'center',
    '&.MuiInputLabel-shrink': {
      transform: 'translate(-50%, 1px) scale(0.75)', // Center horizontally and adjust position
      textAlign: 'center', // Center the text
      maxWidth: '100%', // Prevent overflow
      left: '50%', // Center position
      transformOrigin: '50% -100%', // Ensure transform scales from the center
    }
  };
  const selectStyle = {
    
    backgroundColor: 'whitesmoke',
    borderRadius: 1, // Rounded corners
    borderColor: '#007bff', // Border color
    '&:hover': {
      borderColor: '#007bff', // Border color on hover
      boxShadow: 2, // Box shadow on hover
    },
    '&.Mui-focused': {
      borderColor: '#007bff', // Border color when focused
    }
  };
  // importing global variables from context
  const { userId, setUserId } = useUser();
  // setting global user id to state 
  const [studentId, setStudentId] = useState(userId);
  const [enrolledCourses, setEnrolledCourses] = useState([]);
  const [error, setError] = useState('');
  const [terms, setTerms] = useState([]);  // New state for terms
  const [selectedTerm, setSelectedTerm] = useState('Fall 2023');
  const handleTermChange = (event) => {
    const term = event.target.value;
    setSelectedTerm(term);
    console.log("Selected Term:", selectedTerm);
    fetchEnrolledCourses(term);
  };
  const fetchEnrolledCourses = (term) => {
    axios.post('https://rxr9916.uta.cloud/get_courses.php', {
      student_id: userId,
      selected_term: term
    })
    .then(response => {
      if (Array.isArray(response.data)) {
        setEnrolledCourses(response.data);
      } else {
        setEnrolledCourses([]);
      }
    })
    .catch(error => console.error("Error fetching enrolled courses:", error));
  };
  useEffect(() => {
    axios.get('https://rxr9916.uta.cloud/term.php')
      .then(response => {
        console.log(response.data); // Logging the fetched terms
        setTerms(response.data);
      })
      .catch(error => console.error("Error fetching terms:", error));
  }, []); // Empty dependency array to ensure this runs only once
  useEffect(() => {
    if (selectedTerm) {
      fetchEnrolledCourses(selectedTerm);
    }
  }, [selectedTerm]); // Dependency array with selectedTerm
    
  useEffect(() => {
    if (!studentId) {
      setError('User student data not logged in.');
      return;
    }
   
  });

  return (
    <div className="page-container">
      <div className="main-container">
        <StudentNavbar wrapperClass="left-navbar" menuItemClass="nav-button" />
        <div style={{width:'-webkit-fill-available',display:"flex", flexDirection:"column", alignItems:"center"}}>
         <div className="term-selector">
        {/* <label htmlFor="term-select"><h3>Choose Term: </h3></label> */}
        <br />
        <FormControl fullWidth sx={{ margin: 1, minWidth: 140 }}>
        <InputLabel id="term-select-label" sx={labelStyle}>Select a Term</InputLabel>
        <Select
          labelId="term-select-label"
          id="term-select"
          value={selectedTerm}
          label="Select a Term"
          onChange={handleTermChange}
          sx={selectStyle} // your predefined selectStyle
        >
          
          {terms.map(term => (
            <MenuItem key={term} value={term}>{term}</MenuItem>
          ))}
        </Select>
      </FormControl>
      </div>
        <div className="content">
          
          {error ? (
            <p>{error}</p>
          ) : (
            enrolledCourses.map(course => (
              <div className="course-box" key={course.course_id}>
                
                <Link to={`/course/${course.course_id}`}>
                <h3>{course.course_name}</h3>
                 
                </Link>
                
              </div>
            ))
          )}
        </div>
        </div>
      </div>
    </div>
  );
  
};

export default DashboardCourseList;