import React from "react";
import QANavbar from "./QANavbar";
import axios from "axios";
class QARecommendations extends React.Component {
  constructor(props) {
    super(props);
  }

  state = {
    data: [],
    selectedInstructorId: "",
  };
  handleInstructorChange = (event) => {
    const selectedInstructor = this.state.data.find(instructor => instructor.name === event.target.value);
    this.setState({ selectedInstructorId: selectedInstructor ? selectedInstructor.id : "" });
  }
  

  componentDidMount() {
    //get request

    
    axios.get("https://rxr9916.uta.cloud/fetchinstructor.php").then((res) => {
      // Assuming the response has a key 'instructors' which is an array of instructor objects
      const instructorsList = res.data.instructors ? res.data.instructors : [];
      this.setState({ data: instructorsList });
      console.log(this.state.data);
    }).catch(error => {
      console.error("There was an error fetching the instructors!", error);
    });

      console.log(this.state.data);
    
  }
  handleSubmit = async (event) => {
    event.preventDefault(); // Prevent the default form submission

    const formData = new FormData(event.target);
    const payload = Object.fromEntries(formData.entries());

    try {
      const response = await axios.post("https://rxr9916.uta.cloud/recommend.php", payload);
      console.log(response.data); // Log the response data

      // Check if submission is successful, then reload
      if (response.data.success) {
        window.location.reload();
      } else {
        console.error("Failed to add recommendation:", response.data.message);
      }
    } catch (error) {
      console.error("Error submitting recommendation", error);
    }
  }

  render() {
    return (
      <div className="qrecpage-container">
        <div className="qrecmain-container">
          <QANavbar wrapperClass="left-navbar"
          menuItemClass="nav-button"/>
          <div className="qreccontainer">
            <form action="https://rxr9916.uta.cloud/recommend.php" method="post">
              <fieldset id="info">
                <br />
                <legend>Add Recommendations</legend>
                <br />
                <br />
                <label htmlFor="instructorName">Instructor Name</label>
               
                <select style={{width:"150px", height:"40px", borderRadius:"10px"} } id="instructorName" name="instructorName"  onChange={this.handleInstructorChange}>
                  {this.state.data.map((instructor) => {
                    return (
                      <option
                        key={instructor.id}
                        value={instructor.name}
                      >
                        {instructor.name}
                      </option>
                    );
                  })}
                </select>
                {this.state.data.length > 0 && (
            <div >
              <br/>
              <label>ID </label>
              <br />
              <input name="instructorid" value={this.state.data[0].id}/>
            </div>
          )}
                <br />
                <br />
                <label htmlFor="description">Recommendations:</label>
                <textarea style={{width: "auto", display:"flex", flexDirection:"row", flexWrap:"nowrap"}}
                  id="description"
                  name="address"
                  rows={5}
                  
                ></textarea>
                <br />
                <br />
              </fieldset>
              <br />
              <button type="submit">Submit</button>
            </form>
            <br />
            <br />
            <form className="feedback">
              <fieldset style={{padding:"20px"}}id="info" className="qaorec">
                <p>
                  Here are recommendations for enhancing teaching methods,
                  assessments, and program effectiveness:
                </p>
                <h3>Teaching Methods</h3>
                <ul>
                  <li>Encourage interactive and participatory learning.</li>
                  <li>Incorporate technology for engaging lessons.</li>
                  <li>Regularly assess and adapt teaching strategies.</li>
                </ul>
                <h3>Assessments</h3>
                <p>To improve assessments:</p>
                <ol>
                  <li>
                    <strong>Diversify Assessment Types:</strong> Include a mix
                    of quizzes, projects, and presentations.
                  </li>
                  <li>
                    <strong>Clear Assessment Criteria:</strong> Provide students
                    with transparent grading rubrics.
                  </li>
                  <li>
                    <strong>Feedback Loop:</strong> Establish a feedback
                    mechanism for ongoing improvement.
                  </li>
                </ol>
                <h3>Program Effectiveness</h3>
                <p>For enhancing program effectiveness:</p>
                <ul>
                  <li>Conduct regular program evaluations.</li>
                  <li>Collect feedback from students and instructors.</li>
                  <li>Review and update curriculum as needed.</li>
                </ul>
              </fieldset>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default QARecommendations;
