import React, { useEffect, useState } from "react";
import "../../css/global-styles.css";
import { Link } from "react-router-dom";
import { studentRoutes } from "../../constants";
import StudentNavbar from "./StudentNavbar";
import { useUser } from "../../UserContext";
import axios from 'axios';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';


function StudentExams() {

  const labelStyle = {
    color: '#007bff', // Set the text color to blue
    fontSize: '18px',
    textAlign: 'center',
    '&.MuiInputLabel-shrink': {
      transform: 'translate(-50%, 1px) scale(0.75)', // Center horizontally and adjust position
      textAlign: 'center', // Center the text
      maxWidth: '100%', // Prevent overflow
      left: '50%', // Center position
      transformOrigin: '50% -90%', // Ensure transform scales from the center
    }
  };
  const selectStyle = {
    
    backgroundColor: 'white',
    borderRadius: 1, // Rounded corners
    borderColor: '#007bff', // Border color
    '&:hover': {
      borderColor: '#007bff', // Border color on hover
      boxShadow: 2, // Box shadow on hover
    },
    '&.Mui-focused': {
      borderColor: '#007bff', // Border color when focused
    }
  };

  const { userId, setUserId } = useUser();
  // setting global user id to state 
  const [studentId, setStudentId] = useState(userId);
  const [enrolledCourses, setEnrolledCourses] = useState([]);
  const [error, setError] = useState('');
  const [exams, setExams] = useState([]);
  const [selectedCourseId, setSelectedCourseId] = useState('');
  const [terms, setTerms] = useState([]);  // New state for terms
  const [selectedTerm, setSelectedTerm] = useState('');
  const handleTermChange = (event) => {
    const term = event.target.value;
    setSelectedTerm(term);
    
    fetchEnrolledCourses(term);
  };
  const fetchEnrolledCourses = (term) => {
    axios.post('https://rxr9916.uta.cloud/get_courses.php', {
      student_id: userId,
      selected_term: term
    })
    .then(response => {
      if (Array.isArray(response.data)) {
        setEnrolledCourses(response.data);
      } else {
        setEnrolledCourses([]);
      }
    })
    .catch(error => console.error("Error fetching enrolled courses:", error));
  };
  useEffect(() => {
    axios.get('https://rxr9916.uta.cloud/term.php')
      .then(response => {
        console.log(response.data); // Logging the fetched terms
        setTerms(response.data);
      })
      .catch(error => console.error("Error fetching terms:", error));
  }, []); // Empty dependency array to ensure this runs only once
  useEffect(() => {
    if (!studentId) {
      setError('User student data not logged in.');
      return;
    }
  });
  useEffect(() => {
    if (selectedTerm) {
      fetchEnrolledCourses(selectedTerm);
    }
  }, [selectedTerm]);
  const handleCourseChange = (event) => {
    
    const courseId = event.target.value;
    setSelectedCourseId(courseId);
    handleCourseClick(courseId);
    
  };
  const handleCourseClick = (courseId) => {
    setExams([]);
    axios.post(`https://rxr9916.uta.cloud/getstudentexamsinfo.php`, { course_id: courseId })
      .then(response => {
        // handle response
        // console.log(response.data);
        setExams(response.data.exams || []);
        // possibly update state with the response data
      })
      .catch(error => {
        // handle error
        console.error("Error fetching exams info: ", error);
      });
  };

  return (
    <div className="sepage-container">
      <div className="semain-container">

        <StudentNavbar wrapperClass="left-navbar" menuItemClass="nav-button" />

        <div className="secontent">

          <div className="content">
          <div className="term-selector">
        
          <FormControl fullWidth sx={{ margin: 1, minWidth: 140 }}>
        <InputLabel id="term-select-label" sx={labelStyle}>Select a Term</InputLabel>
        <Select
          labelId="term-select-label"
          id="term-select"
          value={selectedTerm}
          label="Select a Term"
          onChange={handleTermChange}
          sx={selectStyle} // your predefined selectStyle
        >
          
          {terms.map(term => (
            <MenuItem key={term} value={term}>{term}</MenuItem>
          ))}
        </Select>
      </FormControl>
      </div>
      <br />
            {error ? (
              
              <p>{error}</p>
            ) : (

              <FormControl fullWidth sx={{ margin: 1, minWidth: 120 }}>
        <InputLabel id="course-select-label" sx={labelStyle}>
          Select a Course
        </InputLabel>
        <Select
          labelId="course-select-label"
          id="course-select"
          value={selectedCourseId}
          label="Select a Course"
          onChange={handleCourseChange}
          sx={selectStyle} // Reusing the predefined selectStyle
        >
          
          {enrolledCourses.map(course => (
            <MenuItem key={course.course_id} value={course.course_id}>
              {course.course_name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
            )}
          </div>

          <div id="exams" style={{ display: "flex", flexWrap: "wrap" }}>

            {exams.length > 0 ? exams.map((exam, index) => (
              <div className="exam-detail" key={index}>
                <h3>{exam.exam_title}</h3>
                <ul>
                  <li>Course ID: {exam.course_id}</li>
                  <li>Exam ID: {exam.exam_id}</li>
                  
                  <li>Total Marks: {exam.total_marks}</li>
                  <li>Format: {exam.format}</li>
                  <li>Number of Questions: {exam.num_questions}</li>
                  <li>Weightage: {exam.weightage}</li>
                  <li>Time: {exam.time}</li>
                </ul>
              </div>
            )) : <p>No exams available.</p>}
          </div>

        </div>
      </div>
    </div>
  );
}

export default StudentExams;
