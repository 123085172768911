import React ,{useState,useEffect} from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { studentRoutes } from "../../constants";
import "../../css/global-styles.css";
import StudentNavbar from "./StudentNavbar";
import { useUser } from "../../UserContext";
interface UserProfile {
  firstname: string;
  lastname: string;
  email: string;
  phone?: string; // Optional property
  id?: string; // Optional property
}
function StudentFeedback() {
  const [profile, setProfile] = useState<UserProfile>({
    firstname: "",
    lastname: "",
    email: "",
    phone:"",
    // phone and id are optional, so they don't need to be initialized
  });
  const { userId, setUserId } = useUser();
  const [feedbackList, setFeedbackList] = useState([]);
  const [user, setUser] = useState();
  useEffect(() => {
    // Make a POST request to the PHP script
    axios
      .post("https://rxr9916.uta.cloud/profile.php", {
        user_id: userId,
      })
      .then((response) => {
        if (response.data.error) {
          console.error(response.data.error);
        } else {
          setUser(response.data);
          setProfile({
            firstname: response.data.fname,
            lastname: response.data.lname,
            email: response.data.email,
            phone: response.data.phone_number,
            id: response.data.id,
          });
        }
      })
      .catch((error) => {
        console.error("Error fetching user details: ", error);
      });
  }, [userId]);

    useEffect(() => {
      console.log("Current userId:", userId); // Debugging log
    
      if (userId) {
        axios
          .post("https://rxr9916.uta.cloud/fetchfeedbackstudentunique.php", {
            user_id: userId,
          })
          .then((response) => {
            setFeedbackList(response.data);
          })
          .catch((error) => {
            console.error("Error fetching feedback: ", error);
          });
      } else {
        console.error("No userId available");
      }
    }, [userId]);
    

  
  return (
    <div className="page-container">
      <div className="c1main-container">
        <StudentNavbar
          wrapperClass="left-navbar"
          menuItemClass="nav-button"
        />

        <div className="c1mcontent">
          <p id="note">
           
          </p>
          <br />
          <form
            action="https://rxr9916.uta.cloud/feedbackstudent.php"
            className="feedback-form"
            method="post"
          >
            <fieldset id="info" className="studentfeedback">
              <legend>Feedback Form</legend>
              <input type="hidden" name="role" value="student"/>
              <label htmlFor="name">Name:</label>
              <input
                id="name"
                name="name"
                value={`${profile.firstname} ${profile.lastname} `}
                readOnly
                required
                type="text"
              />
              <br />
              <br />
              <label htmlFor="email">Email:</label>
              <input
                id="email"
                name="email"
                readOnly
                required
                type="email"
                value={`${profile.email} `}
              />
              <br />
              <br />
              <label htmlFor="subject">Subject:</label>
              <input id="subject" name="subject" required type="text" />
              <br />
              <br />
              <label htmlFor="feedback">Feedback:</label>
              <textarea
                id="description"
                name="feedback"
                required
                rows={10}
                cols={50}
              ></textarea>
              <br />
              <br />
            </fieldset>
            <br />
            <br />
            <button type="submit">Submit</button>
          </form>
          <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '20px' }}>
            <thead style={{ backgroundColor: '#4CAF50', color: 'white' }}>
              <tr>
                <th style={{ border: '1px solid #ddd', padding: '8px' }}>Feedback ID</th>
                <th style={{ border: '1px solid #ddd', padding: '8px' }}>User ID</th>
                <th style={{ border: '1px solid #ddd', padding: '8px' }}>Name</th>
                <th style={{ border: '1px solid #ddd', padding: '8px' }}>Email</th>
                <th style={{ border: '1px solid #ddd', padding: '8px' }}>Subject</th>
                <th style={{ border: '1px solid #ddd', padding: '8px' }}>Feedback</th>
              </tr>
            </thead>
            <tbody>
              {feedbackList.map((feedback, index) => (
                <tr key={index} style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : 'white' }}>
                  <td style={{ border: '1px solid #ddd', padding: '8px' }}>{feedback.feedbackid}</td>
                  <td style={{ border: '1px solid #ddd', padding: '8px' }}>{feedback.id}</td>
                  <td style={{ border: '1px solid #ddd', padding: '8px' }}>{feedback.name}</td>
                  <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                    <a>
                      {feedback.email}
                    </a>
                  </td>
                  <td style={{ border: '1px solid #ddd', padding: '8px' }}>{feedback.subject}</td>
                  <td style={{ border: '1px solid #ddd', padding: '8px' }}>{feedback.feedback}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
       
      </div>
    </div>
  );
}

export default StudentFeedback;