import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import Header from "../Header";
import { routes, studentRoutes } from "../../constants";
import "../../css/global-styles.css";
import covwdrk from "../../assets/covwdrk.jpg";
import { Link } from "react-router-dom";
import StudentNavbar from "./StudentNavbar";
interface CourseContent {
  course_objective: string;
  // Add other properties if needed based on your API response
}
export default function CourseDetail() {
  const { courseId } = useParams();
  const [courseContent, setCourseContent] = useState<CourseContent | null>(null);
  console.log("Course ID:", courseId);
  useEffect(() => {
    // Make the API call when the component mounts
    fetchCourseContent(courseId);
  }, [courseId]); // Dependency on courseId, so it updates when courseId changes

  const fetchCourseContent = (courseId: string) => {
    // Make the API call here and update the state with the response
    fetch(`https://rxr9916.uta.cloud/getcontentforcourse.php?courseId=${courseId}`)
      .then((response) => response.json())
      .then((data: CourseContent) => {
        // Assuming the API response is of type CourseContent
        setCourseContent(data); // Set the API response in the state
      })
      .catch((error) => {
        console.error("Error fetching course content:", error);
        setCourseContent(null); // Set as null or handle the error appropriately
      });
  };
  return (
    <div className="page-container">
      <div className="head">
        <Header links={[routes.logout]} />
      </div>
      <div className="c1main-container">

        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "20px",
            marginBottom: "20px",
          }}
        >
          <StudentNavbar
            wrapperClass="left-navbar"
            menuItemClass="nav-button"
          />
          <StudentNavbar
            wrapperClass="c1right-navbar"
            menuItemClass="c1nav-button"
            isSubMenu
          />
          <div
            style={{
              width: "60%",
              background: " rgba(255, 255, 255, 0.5)",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img
              src={covwdrk}
              alt="Course_overview"
              style={{ width: "60%", marginTop: 10 }}
            />
            
          

            {courseContent ? ( // Check if courseContent is available
              <p style={{ color: "black", marginLeft: 20 }}>
                {courseContent.course_objective}
              </p>
            ) : (
              <p>Loading...</p> // Display a loading message or handle the absence of data
            )}

            <p style={{ color: "black", marginLeft: 20 }}>
              Key Takeaways:
              <ul>
                <li style={{ color: "black", marginLeft: 20 }}>
                  Master the fundamentals of web development.
                </li>
                <li style={{ color: "black", marginLeft: 20 }}>
                  Learn best practices for coding and design.
                </li>
                <li style={{ color: "black", marginLeft: 20 }}>
                  Gain experience with popular development tools and frameworks.
                </li>
                <li style={{ color: "black", marginLeft: 20 }}>
                  Develop Link portfolio of projects to showcase your skills.
                </li>
              </ul>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}